import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    center: {
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
    },
    title: {
        fontSize: 28,
        fontWeight: "bold",
    },
    header: {
        fontSize: 18,
        fontWeight: "bold",
    },
    lightText: {
        color: "gray",
        fontWeight: "600"
    },
    row: {
        flexDirection: "row",
    },
    bold: {
        fontWeight: "600"
    },
    navbar: {
        // backgroundColor: "rgb(241, 243, 247)",
        minHeight: "100vh",
        width: 210,
        padding: 20
    },
    tabIcon : {
        height: 35,
        width: 35
    },
    input: {
        borderColor: "lightgray",
        borderWidth: 1,
        padding: 10,
        borderRadius: 8,
        minWidth: 250
    },
    signButton: {
        marginTop: 20,
        backgroundColor: "rgb(18,72,251)",
        padding: 10,
        borderRadius: 8,        
    },
    authLink: {
        textAlign: "right",
        marginTop: 10,
        color: "gray",
        fontSize: 12,
        fontWeight: "500"
    },
    tab: {
        left: -5,
        height: 35,
        marginVertical: 2,
        alignItems: "center",
        justifyContent: "left",
        paddingLeft: 2,
        borderRadius: 8,        
    },
    selectedTab: {
        backgroundColor: "rgb(228, 230, 232)",
    },    
    blueFont: {
        color: "rgb(18,72,251)"
    },
    blueBg: {
        color: "rgb(18,72,251)"
    },
    spaceBT: {
        justifyContent: "space-between"
    },
    ppImage: {
        height: 45,
        width: 45,
        borderRadius: 8,
    },
    pp: {
        height: 45, 
        width: 45,
        borderRadius: 8,
        backgroundColor: "rgba(18,72,251,0.15)"
    },
    page: {
        width: "calc(100vw - 210px)",
        height: "100vh",
        paddingVertical: 40,
        paddingHorizontal: 60
    },
    card: {
        width: "calc((100vw - 210px - 120px - 120px) / 3)",
        maxWidth: 383,
        height: 200,
        borderRadius: 8,
        padding: 30
    },
    cardBubble: {
        height: 55,
        width: 55,
        borderRadius: 55
    },
    dualButton: {
        paddingVertical: 10,
        paddingHorizontal: 16, 
        borderRadius: 8,
    },
    dualButtonSelected: {
        backgroundColor: "rgb(18,72,251)"
    }
});
