import { ScrollView, Text, TouchableOpacity, View } from "react-native"
import { Modal } from "../../helpers/Modal"
import { useNavigation } from "@react-navigation/native";
import { styles } from "../../Styles";
import { getDateFrenchFormat } from "../../helpers/functions";

export const TasksListModal = ({ doctor }) => {

    const tasks = doctor.tasks;
    const navigation = useNavigation();

    return (
        <Modal title={"Liste des tâches"}>
            {
                tasks.length == 0 && <Text style={{ textAlign: "center", marginTop: 20 }}>Aucune tâche</Text>
            }
            {
                tasks.length > 0 &&
                <ScrollView style={{ maxHeight: 350 }} showsVerticalScrollIndicator={false}>
                    {
                        tasks.map((task, index) => {
                            return (
                                <TouchableOpacity onPress={() => navigation.navigate("TaskModal", {task: task})} key={index} style={{ marginBottom: index == tasks.length - 1 ? 0 : 10, paddingVertical: 10, paddingHorizontal: 15, borderRadius: 8, backgroundColor: "rgba(251,147,18,0.15)", width: 400 - 40 }}>
                                    <Text style={styles.bold}>{task.title}</Text>
                                    <Text style={styles.lightText}>Le {getDateFrenchFormat(task.date)} de {String(task.from).slice(0, 5).replace(":", "h")} à {String(task.to).slice(0, 5).replace(":", "h")}</Text>
                                </TouchableOpacity>
                            )
                        })
                    }
                </ScrollView>
            }
        </Modal>
    )
}