import { View, Text, ScrollView, Image, TouchableOpacity, TextInput, ActivityIndicator } from "react-native";
import { styles } from "../../Styles";
import { Modal } from "../../helpers/Modal"
import { getDateFrenchFormat, isDate1GreaterOrEqual, isDateValid } from "../../helpers/functions";
import { useRef, useState } from "react";
import { updateAbsence } from "../../supabase";

export const MissingModal = ({ doctor, setDoctor }) => {

    const dates = doctor.availability.abs_date;

    const yearRef = useRef();
    const monthRef = useRef();
    const dayRef2 = useRef();
    const yearRef2 = useRef();
    const monthRef2 = useRef();

    const now = new Date();

    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState(now.getFullYear());
    const [day2, setDay2] = useState("");
    const [month2, setMonth2] = useState("");
    const [year2, setYear2] = useState(now.getFullYear());

    const [loading, setLoading] = useState(false);

    const checkInputs = (day, month, year) => {

        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            return alert("Les champs doivent être des nombres.")
        }

        if (day < 1 || day > 31) {
            return alert("Le jour doit être compris entre 1 et 31.");
        }

        if (month < 1 || month > 12) {
            return alert("Le mois doit être compris entre 1 et 12.");
        }

        if (!isDate1GreaterOrEqual(year, month, day, now.getFullYear(), now.getMonth() + 1, now.getDate())) {
            return alert("La date doit être dans le futur.");
        }

        if (!isDateValid(year, month, day)) {
            return alert("La date que vous avez entrée n'est pas valide.")
        }

        return true;
    }

    const checkDates = async () => {
        checkInputs(day, month, year);
        checkInputs(day2, month2, year2);
        if (!isDate1GreaterOrEqual(year2, month2, day2, year, month, day)) {
            return alert("La seconde date doit être supérieure ou égale à la première.")
        }
        // checker si superposition de dates avec rdv, si oui, alert, si non, ajouter à la db et en local
        for (let i = 0; i < doctor.rdv.length; i++) {
            const rdv = doctor.rdv[i];
            const date = new Date(rdv.date).getTime() - (2 * 60 * 60 * 1000);
            const min = new Date(year, month - 1, day).getTime();
            const max = new Date(year2, month2 - 1, day2).getTime();
            if (date >= min && date <= max) {
                return alert("Vous avez un rendez-vous sur cette période !");
            }
            // ajouter à la db et en local
            const arr = [...doctor.availability.abs_date, [`${year}-${month}-${day}`, `${year2}-${month2}-${day2}`]]
            const data = await updateAbsence(arr, doctor.uuid);
            if (data) {
                setDoctor((old) => ({
                    ...old,
                    availability: {
                        abs_date: arr,
                        agenda: old.availability.agenda,
                    }
                }))
            }
        }
    }

    const deleteIndex = async (i) => {
        const arr = doctor.availability.abs_date.slice(0)
        arr.splice(i, 1);
        const data = await updateAbsence(arr, doctor.uuid);
        if (data) {
            setDoctor((old) => ({
                ...old,
                availability: {
                    abs_date: arr,
                    agenda: old.availability.agenda,
                }
            }))
        }
    }

    return (
        <Modal title="Ajouter un période d'absence">
            {
                dates ? (
                    <>
                        <Text style={[styles.bold, { marginBottom: 10 }]}>Vous avez {dates.length} absences programmées :</Text>
                        <ScrollView style={{ maxHeight: 350 }}>
                            {
                                dates.map((date, i) => {
                                    return (
                                        <View key={i} style={[styles.row, styles.spaceBT, { alignItems: "center", marginBottom: i == dates.length - 1 ? 0 : 10, paddingVertical: 10, paddingHorizontal: 15, borderRadius: 8, backgroundColor: "rgba(251,147,18,0.15)" }]} >
                                            <Text style={styles.bold}>Du {getDateFrenchFormat(date[0])} au {getDateFrenchFormat(date[1])}</Text>
                                            <TouchableOpacity onPress={async () => {
                                                await deleteIndex(i);
                                            }}>
                                                <Image source={require("../../../assets/icons/trash.png")} style={{ height: 30, width: 30 }} />
                                            </TouchableOpacity>
                                        </View>
                                    )
                                })
                            }
                        </ScrollView>
                    </>
                ) : (
                    <View>
                        <Text style={[styles.lightText]}>Vous n'avez aucune dates d'absence pour le moment</Text>
                    </View>
                )
            }
            <Text style={[styles.bold, { marginBottom: 5, marginTop: 15 }]}>Ajouter une periode</Text>
            <View style={[styles.row, styles.spaceBT, { alignItems: "center" }]}>
                <Text style={[{ marginRight: 5, fontSize: 18 }, styles.bold]}>Du</Text>
                <TextInput onSubmitEditing={() => monthRef.current.focus()} style={[styles.input, { minWidth: 20, maxWidth: "30%" }]} placeholderTextColor="gray" placeholder="Jour" value={day} onChangeText={(e) => setDay(e)} />
                <Text style={[styles.title, { marginHorizontal: 5 }]}>/</Text>

                <TextInput ref={monthRef} onSubmitEditing={() => yearRef.current.focus()} style={[styles.input, { minWidth: 20 }]} placeholderTextColor="gray" placeholder="Mois" value={month} onChangeText={(e) => setMonth(e)} />
                <Text style={[styles.title, { marginHorizontal: 5 }]}>/</Text>

                <TextInput ref={yearRef} onSubmitEditing={() => dayRef2.current.focus()} style={[styles.input, { minWidth: 40 }]} placeholderTextColor="gray" placeholder="Année" value={year} onChangeText={(e) => setYear(e)} />
            </View>
            <View style={[styles.row, styles.spaceBT, { alignItems: "center", marginTop: 10 }]}>
                <Text style={[{ marginRight: 5, fontSize: 18 }, styles.bold]}>Au</Text>
                <TextInput ref={dayRef2} onSubmitEditing={() => monthRef2.current.focus()} style={[styles.input, { minWidth: 20, maxWidth: "30%" }]} placeholderTextColor="gray" placeholder="Jour" value={day2} onChangeText={(e) => setDay2(e)} />
                <Text style={[styles.title, { marginHorizontal: 5 }]}>/</Text>

                <TextInput ref={monthRef2} onSubmitEditing={() => yearRef2.current.focus()} style={[styles.input, { minWidth: 20 }]} placeholderTextColor="gray" placeholder="Mois" value={month2} onChangeText={(e) => setMonth2(e)} />
                <Text style={[styles.title, { marginHorizontal: 5 }]}>/</Text>

                <TextInput ref={yearRef2} onSubmitEditing={async () => {
                    setLoading(true);
                    await checkDates();
                    setLoading(false);
                }} style={[styles.input, { minWidth: 40 }]} placeholderTextColor="gray" placeholder="Année" value={year2} onChangeText={(e) => setYear2(e)} />
            </View>
            <TouchableOpacity disabled={loading} style={styles.signButton} onPress={async () => {
                setLoading(true);
                await checkDates();
                setLoading(false);
            }}>
                {loading ? <ActivityIndicator color={"white"} /> : <Text style={[styles.bold, { color: "white", textAlign: "center" }]}>Ajouter</Text>}
            </TouchableOpacity>

        </Modal>
    )
}