import { createClient } from "@supabase/supabase-js";

const stripeProductId = "prod_OFGkMwCyCB7St3";
export const supabase = createClient("https://fnontbrkxrkzgzdiqtkb.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZub250YnJreHJremd6ZGlxdGtiIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODc5NTI2MDQsImV4cCI6MjAwMzUyODYwNH0.e_PZuSCVqi1EKXXNt-zT94Ie37Hz8lEs6wZQxeP_YUU")

export const listenAuth = (setSession) => {
    supabase.auth.onAuthStateChange((event, session) => {
        setSession(session)
    })
}

export const checkIfDoctor = async (uuid) => {
    const { data, error } = await supabase.from("doctors").select().eq("uuid", uuid);

    if (error) {
        console.log("err", error);
    }

    if (data.length == 1) {
        return true;
    }
    return false;
}

export const signUp = async (email, pwd, name, lastname, address, city, postCode) => {
    const { data, error } = await supabase.auth.signUp({
        email: email,
        password: pwd,
        options: {
            data: {
                name: name,
                lastname: lastname,
                address: address,
                city: city,
                postcode: postCode,
            }
        }
    })

    if (error) {
        alert("Une erreur est survenue lors de l'inscription")
        return error;
    }

    return data;
}

export const signIn = async (email, pwd) => {
    const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: pwd
    });

    if (error) {
        if (String(error).includes("Email not confirmed")) {
            alert("Veuillez confirmer votre adresse email avant de vous connecter, un email de confirmation vous a été envoyé.");
            return error;
        }
        alert("Une erreur est survenue lors de la connexion.")
        return error;
    }
}

export const fetchDoctor = async (uuid) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const { data, error } = await supabase.from("doctors").select("*, tasks(*), availability(abs_date, agenda), rdv:decrypted_rdv(id, created_at, user_id, date, from, to, reason:decrypted_reason, client:clients(*)))").eq("uuid", uuid).gte("decrypted_rdv.date", formattedDate).gte("tasks.date", formattedDate);

    if (error) {
        console.log("err", error);
        return;
    }

    if (data.length == 1) {              
        return data;
    }

}

export const getClientsFromDoctor = async (uuid) => {
    const { data, error } = await supabase.rpc("get_unique_clients_by_doctor_id", { doctor_id: uuid })

    if (error) {
        console.log("err", error)
        return;
    }

    return data;
}

export const getRdvOfClient = async (user_uuid, doctor_uuid) => {
    const { data, error } = await supabase.from("decrypted_rdv").select("*, reason:decrypted_reason").eq("user_id", user_uuid).eq("doctor_id", doctor_uuid).order("created_at", { ascending: false });

    if (error) {
        console.log("err", error)
        return;
    }

    return data;
}

export const logout = async () => {
    return await supabase.auth.signOut();
}

export const modifyDocColumn = async (uuid, obj) => {
    const { data, error } = await supabase.from("doctors").update(obj).eq("uuid", uuid).select();

    if (error) {
        console.log("err", error)
        alert("Une erreur est survenue pendant la modification des données.")
        return;
    }

    return data[0];
}

export const updateEdt = async (d, uuid) => {

    var max_length = 0

    const da = [...d]

    for (let i = 0; i < da.length; i++) {
        const day = da[i];

        for (let j = 0; j < day.length; j++) {
            const rdv = day[j];
            if (rdv[0] == "00:00:00" && rdv[1] == "00:00:00") {
                day.splice(j, 1);
            }
        }


        if (day.length > max_length) {
            max_length = day.length
        }
    }

    for (let i = 0; i < da.length; i++) {
        const len = max_length - da[i].length;

        for (let j = 0; j < len; j++) {
            da[i].push(["00:00:00", "00:00:00"])
        }
    }

    const { data, error } = await supabase.from("availability").update({ agenda: da }).eq("doctor_id", uuid).select();

    if (error) {
        console.log("err", error)
        alert("Une erreur est survenue pendant la modification des données.")
        return;
    }

    return data;
}

export const uploadProfilePicture = async (file, uuid) => {
    const { data: uploadedFile, error: uploadError } = await supabase.storage
        .from('profile')
        .upload(`${uuid}/profile`, file, {
            cacheControl: 'public, max-age=31536000',
            upsert: true,
        });

    if (uploadError) {
        console.error('Error uploading file:', uploadError);
        return;
    }

    const { error: updateError } = await supabase
        .from('doctors')
        .update({ profile_uri: uploadedFile.Key })
        .eq('uuid', uuid);

    if (updateError) {
        console.error('Error updating doctor record:', updateError);
        return;
    }

}

export const callStripe = async (uuid, name, lastname, email, address, city, postCode) => {
    const { data, error } = await supabase.functions.invoke("stripeClient", {
        body: {
            uuid: uuid,
            name: name,
            lastname: lastname,
            email: email,
            address: address,
            city: city,
            postcode: postCode,
        }
    })

    if (error) {
        console.log("err", error)
        return;
    }

    return data;
}

export const addTask = async (uuid, title, content, from, to, date) => {
    const { error, data } = await supabase.from("tasks").insert([{ doctor_id: uuid, title: title, content: content, from: from, to: to, date: date }]).select();

    if (error) {
        console.log("err", error)
        return;
    }

    return data[0];
}

export const removeTask = async (id) => {
    const { error, data } = await supabase.from("tasks").delete().eq("id", id);

    if (error) {
        console.log("err", error)
        return;
    }

    return data;
}

export const getClientData = async (uuid) => {
    const { data, error } = await supabase.from("clients").select().eq("uuid", uuid);

    if (error) {
        console.log("err", error)
        return;
    }

    return data;
}

export const getClientFromPhone = async (phone) => {
    const { data, error } = await supabase.from("clients").select().eq("phone", phone).limit(1);

    if (error) {
        console.log("err", error)
        return;
    }

    return data[0];
}

export const getClientFromName = async (name, lastname) => {
    const { data, error } = await supabase.from("clients").select().ilike("name", `%${name}%`).ilike("lastname", `%${lastname}%`).limit(1);

    if (error) {
        console.log("err", error)
        return;
    }

    return data[0];
}

export const addRdv = async (doctor_id, user_id, date, from, to, reason) => {
    const { data, error } = await supabase.from("rdv").insert([{ doctor_id: doctor_id, user_id: user_id, date: date, from: from, to: to, reason: reason }]).select();

    if (error) {
        console.log("err", error)
        return;
    }

    return data[0];
}

export const isDoctorSub = async (stripe_id) => {
    try {
        const apiKey = 'sk_test_51NSlf2BZDZbHOEGKik0uErtIy7nfQzIeSPosW61w0RsweuyyJtT53FseFHcNQpJRZub1xXY98gbchjg6m5MJowk400zDeZkvjR';
        const apiUrl = `https://api.stripe.com/v1/customers/${stripe_id}?expand[]=subscriptions`;

        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${apiKey}`,
            },
        };

        const response = await fetch(apiUrl, requestOptions);
        if (response.ok) {
            const data = await response.json();            
            for (let i = 0; i < data.subscriptions.data.length; i++) {
                if (data.subscriptions.data[i].plan.product == stripeProductId) {                    
                    return true;
                }
            }
            return false;
        }
        return false;
    } catch (e) {
        console.log("error", e)
        return false;
    }
}

export const updateAbsence = async (arr, uuid) => {
    const { data, error } = await supabase.from("availability").update({ abs_date: arr }).eq("doctor_id", uuid).select();

    if (error) {
        console.log("err", error)
        alert("Une erreur est survenue pendant la modification des données.")
        return;
    }

    return data;
} 