import { useEffect, useState } from "react";
import { Modal } from "../../helpers/Modal"
import { calculateDuration, getDateFrenchFormat } from "../../helpers/functions";
import { getRdvOfClient } from "../../supabase";
import { useNavigation, useRoute } from "@react-navigation/native";
import { ActivityIndicator, ScrollView, Text, View } from "react-native";
import { styles } from "../../Styles";

export const PatientModal = ({doctor}) => {

    const route = useRoute()
    const client = route.params.client;

    const [rdvs, setRdvs] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            const data = await getRdvOfClient(client.uuid, doctor.uuid)
            setRdvs(data)
            setLoading(false)
        }

        fetchData()
    }, [])

    const dif = calculateDuration(client.birthdate, new Date())

    return (
        <Modal title={`Fiche client de ${client.sex == 0 ? "Mme." : client.sex == 1 ? "M." : null} ${client.lastname}`}>
            {loading ?
                <ActivityIndicator size={"small"} color={"rgb(18,72,251)"} />
                :
                <View style={[{ width: "100%" }]}>
                    <View>
                        <ScrollView style={{ maxHeight: 350 }} showsVerticalScrollIndicator={false}>
                            {
                                client.lastname &&
                                <Text style={{ marginBottom: 10 }}><Text style={{ fontWeight: "600" }}>Nom :</Text> {client.lastname}</Text>
                            }
                            {
                                client.name &&
                                <Text style={{ marginBottom: 10 }}><Text style={{ fontWeight: "600" }}>Prénom :</Text> {client.name}</Text>
                            }
                            {
                                client.birthdate &&
                                <Text style={{ marginBottom: 10 }}><Text style={{ fontWeight: "600" }}>Date de naissance : </Text>{getDateFrenchFormat(client.birthdate)} ({dif.years} ans, {dif.months} mois)</Text>
                            }
                            {
                                client.phone &&
                                <Text style={{ marginBottom: 10 }}><Text style={{ fontWeight: "600" }}>Téléphone :</Text> {client.phone}</Text>
                            }
                            <Text style={{ marginBottom: 10 }}><Text style={{ fontWeight: "600" }}>Nombre de rendez-vous :</Text> {rdvs.length}</Text>
                            {
                                rdvs.map((rdv, index) => {
                                    return (
                                        <View key={index} style={{ marginBottom: index == rdvs.length - 1 ? 0 : 10, paddingVertical: 10, paddingHorizontal: 15, borderRadius: 8, backgroundColor: "rgba(18,72,251,0.15)", width: 400 - 40 }}>
                                            <Text>{getDateFrenchFormat(rdv.date)}, de {String(rdv.from).slice(0, 5)} à {String(rdv.to).slice(0, 5)}</Text>
                                            <Text style={styles.lightText}>{rdv.reason}</Text>
                                        </View>
                                    )
                                })
                            }
                        </ScrollView>
                    </View>
                </View>
            }
        </Modal>
    )
}