import { ActivityIndicator, Text, TextInput, TouchableOpacity, View } from "react-native"
import { Modal } from "../../helpers/Modal"
import { useNavigation, useRoute } from "@react-navigation/native"
import { styles } from "../../Styles"
import { DAYS, days, isDate1GreaterOrEqual, isDateValid } from "../../helpers/functions"
import { useRef, useState } from "react"
import { addTask, updateEdt } from "../../supabase"
import { NumberInput } from "../../helpers/NumberInput"

const RdvPart = ({ loading, setLoading, navigation, doctor, setDoctor, id }) => {

    const [from_h, setFrom_h] = useState(7)
    const [from_m, setFrom_m] = useState(0)
    const [to_h, setTo_h] = useState(7)
    const [to_m, setTo_m] = useState(45)

    var dif = (to_h + to_m / 60) - (from_h + from_m / 60)
    var duration_h = Math.floor(dif)
    var duration_m = Number((dif - Math.floor(dif)) * 60).toFixed(0);

    return (
        <>
            <View style={[styles.row, { alignItems: "center" }]}>
                <Text style={{ fontSize: 12 }}>Début : </Text>
                <NumberInput min={7} max={20} val={from_h} setVal={setFrom_h} />
                <Text style={{ fontSize: 12 }}> h </Text>
                <NumberInput min={0} max={59} val={from_m} setVal={setFrom_m} />
            </View>
            <View style={[styles.row, { alignItems: "center", marginVertical: 10 }]}>
                <Text style={{ fontSize: 12 }}>Fin : </Text>
                <NumberInput min={7} max={20} val={to_h} setVal={setTo_h} />
                <Text style={{ fontSize: 12 }}> h </Text>
                <NumberInput min={0} max={59} val={to_m} setVal={setTo_m} />
            </View>

            <Text style={[styles.lightText, { fontSize: 12 }]}>Durée du rendez-vous :  {dif > 0 ? `${duration_h}h${duration_m < 10 ? "0" + duration_m : duration_m}` : "0h"}</Text>
            <TouchableOpacity disabled={loading} onPress={async () => {
                if (doctor.availability) {
                    setLoading(true);
                    if (dif < 1 / 4) {
                        setLoading(false)
                        return alert("La plage horraire choisie est trop courte.")
                    }
                    for (let i = 0; i < doctor.availability.agenda[id].length; i++) {
                        const rdv = doctor.availability.agenda[id][i]
                        const f_h = Number(rdv[0].slice(0, 2))
                        const f_m = Number(rdv[0].slice(3, 5))
                        const f = f_h + f_m / 60

                        const t_h = Number(rdv[1].slice(0, 2))
                        const t_m = Number(rdv[1].slice(3, 5))
                        const t = t_h + t_m / 60

                        const current_f = from_h + from_m / 60
                        const current_t = to_h + to_m / 60

                        const currentIn = (current_f > f && current_f < t) || (current_t > f && current_t < t)
                        const currentContain = current_f <= f && current_t >= t

                        if (currentIn || currentContain) {
                            setLoading(false)
                            return alert("Aucune modification possible, le crénneau est déja pris.")
                        }


                    }

                    const agenda = [...doctor.availability.agenda];
                    const newTiming = [`${from_h < 10 ? "0" + from_h : from_h}:${from_m < 10 ? "0" + from_m : from_m}:00`, `${to_h < 10 ? "0" + to_h : to_h}:${to_m < 10 ? "0" + to_m : to_m}:00`]

                    agenda[id].push(newTiming);

                    const data = await updateEdt(agenda, doctor.uuid)

                    if (data) {

                        setDoctor(old => ({
                            ...old,
                            availability: {
                                "agenda": agenda,
                            }
                        }))

                        navigation.navigate("Home")
                    }

                    setLoading(false)
                }
            }} style={{ marginTop: 20, justifyContent: "center", alignItems: "center", padding: 10, backgroundColor: "rgb(18,72,251)", borderRadius: 10 }}>
                {loading ? <ActivityIndicator color={"white"} /> : <Text style={{ color: "white", fontWeight: "600" }}>Ajouter l'horaire</Text>}
            </TouchableOpacity>
        </>
    )
}

export const AgendaAddTimingModal = ({ doctor, setDoctor }) => {

    const navigation = useNavigation();
    const route = useRoute()
    const { isTask, day, weekDay, date, from, to, id } = route.params;

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [loading, setLoading] = useState(false);

    var from_h, from_m, to_h, to_m;

    if (isTask) {
        from_h = from[0];
        from_m = from[1];
        to_h = to[0];
        to_m = to[1];
    }

    const contentRef = useRef();

    const checkTask = async () => {
        // check overlay        
        setLoading(true)
        for (let i = 0; i < doctor.availability.agenda[(weekDay) % 7].length; i++) {
            const rdv = doctor.availability.agenda[(weekDay) % 7][i]
            const f_h = Number(rdv[0].slice(0, 2))
            const f_m = Number(rdv[0].slice(3, 5))
            const f = f_h + f_m / 60

            const t_h = Number(rdv[1].slice(0, 2))
            const t_m = Number(rdv[1].slice(3, 5))
            const t = t_h + t_m / 60

            const current_f = from_h + from_m / 60
            const current_t = to_h + to_m / 60

            const currentIn = (current_f > f && current_f < t) || (current_t > f && current_t < t)
            const currentContain = current_f <= f && current_t >= t

            if (currentIn || currentContain) {
                console.log(rdv)
                setLoading(false)
                navigation.goBack()
                return alert("Impossible d'ajouter la tâche, elle chevauche un créneau existant.")
            }


        }

        const tasksOfThatSpecificDay = doctor.tasks.filter(t => t.date == date)
        for (let i = 0; i < tasksOfThatSpecificDay.length; i++) {
            const rdv = tasksOfThatSpecificDay[i]
            const f_h = Number(rdv.from.slice(0, 2))
            const f_m = Number(rdv.from.slice(3, 5))
            const f = f_h + f_m / 60

            const t_h = Number(rdv.to.slice(0, 2))
            const t_m = Number(rdv.to.slice(3, 5))
            const t = t_h + t_m / 60

            const current_f = from_h + from_m / 60
            const current_t = to_h + to_m / 60

            const currentIn = (current_f > f && current_f < t) || (current_t > f && current_t < t)
            const currentContain = current_f <= f && current_t >= t

            if (currentIn || currentContain) {
                setLoading(false)
                navigation.goBack()
                return alert("Impossible d'ajouter la tâche, elle chevauche une tâche existante.")
            }
        }

        // add task (no overlay)
        const task = await addTask(doctor.uuid, title, content, `${String(from_h).padStart(2, "0")}:${String(from_m).padStart(2, "0")}:00`, `${to_h}:${to_m}:00`, date)
        const newTasks = [...doctor.tasks, task]
        setDoctor(old => ({
            ...old,
            tasks: newTasks,
        }))
        setLoading(false)
        navigation.navigate("Home")
    }

    return (
        <Modal title={String("Ajouter " + (!isTask ? "un créneau" : "une tâche") + " pour le " + day)}>
            {
                isTask ? (
                    <View>
                        <Text style={[styles.bold, { marginBottom: 5 }]}>Titre</Text>
                        <TextInput autoFocus onSubmitEditing={() => contentRef.current.focus()} style={styles.input} placeholderTextColor="gray" value={title} onChangeText={(e) => setTitle(e)} placeholder="Entrez un titre" />

                        <Text style={[styles.bold, { marginBottom: 5, marginTop: 10 }]}>Contenu</Text>
                        <TextInput onSubmitEditing={() => checkTask()} ref={contentRef} style={styles.input} placeholderTextColor="gray" value={content} onChangeText={(e) => setContent(e)} placeholder="Entrez un contenu" />

                        <TouchableOpacity disabled={loading} style={styles.signButton} onPress={async () => {
                            checkTask();
                        }}>
                            {loading ? <ActivityIndicator color={"white"} /> : <Text style={{ textAlign: "center", color: "white", fontWeight: "600" }}>Ajouter la tâche</Text>}
                        </TouchableOpacity>
                    </View>
                ) : (
                    <RdvPart loading={loading} setLoading={setLoading} navigation={navigation} doctor={doctor} setDoctor={setDoctor} id={id} />
                )
            }
        </Modal>
    )
}

export const AgendaAddTaskModal = ({ doctor, setDoctor }) => {

    const navigation = useNavigation()
    const route = useRoute();
    const { agenda } = route.params;

    const today = new Date();

    const [day, setDay] = useState(today.getDate());
    const [month, setMonth] = useState(today.getMonth() + 1);
    const [year, setYear] = useState(today.getFullYear());

    const [from_h, setFrom_h] = useState(7);
    const [from_m, setFrom_m] = useState(0);
    const [to_h, setTo_h] = useState(8);
    const [to_m, setTo_m] = useState(0);

    const monthRef = useRef();
    const yearRef = useRef();

    const checkInputs = () => {

        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            return alert("Les champs doivent être des nombres.")
        }

        if (day < 1 || day > 31) {
            return alert("Le jour doit être compris entre 1 et 31.");
        }

        if (month < 1 || month > 12) {
            return alert("Le mois doit être compris entre 1 et 12.");
        }

        if (from_h > to_h || (from_h == to_h && from_m > to_m)) {
            return alert("L'heure de début doit être avant l'heure de fin.");
        }

        if (!isDate1GreaterOrEqual(year, month, day, today.getFullYear(), today.getMonth() + 1, today.getDate())) {
            return alert("La date doit être dans le futur.");
        }

        if (!isDateValid(year, month, day)) {
            return alert("La date que vous avez entrée n'est pas valide.")
        }

        navigation.navigate("AgendaAddTimingModal", { isTask: true, date: `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`, day: day, weekDay: (new Date(year, month - 1, day).getDay() + 6) % 7, from: [from_h, from_m], to: [to_h, to_m] });
    }

    return (
        <Modal title="Ajouter une tâche">
            <Text style={{ textAlign: "left", marginBottom: 10 }}>Pour quel jour voulez vous ajouter une tâche ?</Text>
            <View style={{ marginTop: 10 }}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text style={{ fontSize: 12 }}>Début : </Text>
                    <NumberInput min={7} max={20} val={from_h} setVal={setFrom_h} />
                    <Text style={{ fontSize: 12 }}> h </Text>
                    <NumberInput min={0} max={59} val={from_m} setVal={setFrom_m} />
                </View>
                <View style={{ flexDirection: "row", alignItems: "center", marginVertical: 10 }}>
                    <Text style={{ fontSize: 12 }}>Fin : </Text>
                    <NumberInput min={7} max={20} val={to_h} setVal={setTo_h} />
                    <Text style={{ fontSize: 12 }}> h </Text>
                    <NumberInput min={0} max={59} val={to_m} setVal={setTo_m} />
                </View>
            </View>
            <View style={[styles.row, styles.spaceBT, { alignItems: "center" }]}>
                <TextInput onSubmitEditing={() => monthRef.current.focus()} style={[styles.input, { minWidth: 20 }]} placeholderTextColor="gray" placeholder="Jour" value={day} onChangeText={(e) => setDay(e)} />
                <Text style={[styles.title, { marginHorizontal: 5 }]}>/</Text>

                <TextInput ref={monthRef} onSubmitEditing={() => yearRef.current.focus()} style={[styles.input, { minWidth: 20 }]} placeholderTextColor="gray" placeholder="Mois" value={month} onChangeText={(e) => setMonth(e)} />
                <Text style={[styles.title, { marginHorizontal: 5 }]}>/</Text>

                <TextInput ref={yearRef} onSubmitEditing={() => checkInputs()} style={[styles.input, { minWidth: 40 }]} placeholderTextColor="gray" placeholder="Année" value={year} onChangeText={(e) => setYear(e)} />
            </View>

            <TouchableOpacity style={styles.signButton} onPress={async () => {
                checkInputs()
            }}>
                <Text style={{ textAlign: "center", color: "white", fontWeight: "600" }}>Ajouter la tâche le {day}/{month}/{year}</Text>
            </TouchableOpacity>

        </Modal>
    )
}

export const AgendaAddRdvModal = () => {

    const navigation = useNavigation();

    return (
        <Modal title="Ajouter un horaire">
            <Text style={{ textAlign: "left", marginBottom: 10 }}>Pour quel jour voulez vous ajouter un horaire ?</Text>
            <View style={[styles.row, { justifyContent: "space-between" }]}>
                {
                    DAYS.map((el, i) => {
                        return (
                            <TouchableOpacity key={i} style={{ backgroundColor: "rgb(18,72,251)", borderRadius: 10, padding: 10, marginRight: i == 6 ? 0 : 6.5 }} onPress={() => {
                                navigation.navigate("AgendaAddTimingModal", { "id": i, isTask: false, day: days[(i + 1) % 7] })
                            }}>
                                <Text style={{ color: "white", fontWeight: "600" }}>{DAYS[(i + 1) % 7]}</Text>
                            </TouchableOpacity>
                        )
                    })
                }
            </View>
        </Modal>
    )
}