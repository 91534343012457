import { Text, TouchableOpacity, View } from "react-native"
import { Modal } from "../../helpers/Modal"
import { calculateDuration, getDateFrenchFormat, getName } from "../../helpers/functions"
import { useNavigation, useRoute } from "@react-navigation/native";
import { styles } from "../../Styles";

export const RdvModal = () => {

    const route = useRoute();
    const rdv = route.params.rdv;  
    const client = rdv.client;    

    const navigation = useNavigation();

    return (
        <Modal>
            <View style={styles.row}>
                <View style={styles.pp} />
                <TouchableOpacity onPress={() => navigation.navigate("PatientModal", { client: client })} style={{ marginLeft: 10, justifyContent: "center" }}>
                    <Text style={{ fontWeight: "600" }}>{getName(client.name, client.lastname)}</Text>
                </TouchableOpacity>
            </View>
            <Text style={[styles.lightText, { marginTop: 20 }]}>Rendez-vous de {rdv.from.slice(0, 5).replace(":", "h")} à {rdv.to.slice(0, 5).replace(":", "h")} le {getDateFrenchFormat(rdv.date)}</Text>
            <Text style={[styles.lightText, { color: "gray" }]}>Le rendez-vous a été pris le {getDateFrenchFormat(rdv.created_at.slice(0, 10))}</Text>
            <Text style={{ marginTop: 20 }}>Raison du rendez-vous : {rdv.reason}</Text>
        </Modal>
    )
}