import { View, Text, TextInput, TouchableOpacity, ActivityIndicator } from "react-native"
import { styles } from "../Styles"
import { useState, useRef } from "react"
import { useNavigation } from "@react-navigation/native";
import { callStripe, signUp } from "../supabase";

export const SignUp = () => {

    const [email, setEmail] = useState("");
    const [pwd, setPwd] = useState("");
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [postCode, setPostCode] = useState("");

    const nameRef = useRef();
    const lastNameRef = useRef();
    const cityRef = useRef();
    const postCodeRef = useRef();

    const [showPwd, setShowPwd] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigation = useNavigation()

    const signUpWithNavigation = async () => {
        setLoading(true)
        await signUp(email, pwd, name, lastName, address, city, postCode);
        setLoading(false);
        alert("Vous avez reçu un email de confirmation, veuillez cliquez sur le lien.");
        return navigation.navigate("SignIn", { e: email })

    }

    return (
        <View style={styles.center}>
            <View>
                <Text style={[styles.title, { marginBottom: 20, textAlign: "center" }]}>S'inscrire</Text>
                {!showAddress ?
                    <>
                        <Text style={[styles.bold, { marginBottom: 5 }]}>Email</Text>
                        <TextInput onSubmitEditing={() => setShowPwd(true)} style={styles.input} placeholderTextColor="gray" value={email} onChangeText={(e) => setEmail(e)} placeholder="Entrez votre email" />

                        {
                            showPwd && (
                                <>
                                    <Text style={[styles.bold, { marginBottom: 5, marginTop: 15 }]}>Mot de passe</Text>
                                    <TextInput autoFocus onSubmitEditing={() => lastNameRef.current.focus()} style={styles.input} secureTextEntry placeholderTextColor="gray" value={pwd} onChangeText={(e) => setPwd(e)} placeholder="Entrez votre mot de passe" />

                                    <Text style={[styles.bold, { marginBottom: 5, marginTop: 15 }]}>Nom</Text>
                                    <TextInput onSubmitEditing={() => nameRef.current.focus()} ref={lastNameRef} style={styles.input} placeholderTextColor="gray" value={lastName} onChangeText={(e) => setLastName(e)} placeholder="Entrez votre nom" />

                                    <Text style={[styles.bold, { marginBottom: 5, marginTop: 15 }]}>Prénom</Text>
                                    <TextInput ref={nameRef} onSubmitEditing={() => {
                                        if (pwd.length < 6) {
                                            return alert("Le mot de passe doit contenir au moins 6 caractères");
                                        }

                                        if (name.length < 2) {
                                            return alert("Le prénom doit contenir au moins 2 caractères");
                                        }

                                        if (lastName.length < 2) {
                                            return alert("Le nom doit contenir au moins 2 caractères");
                                        }

                                        setShowAddress(true);
                                    }} style={styles.input} placeholderTextColor="gray" value={name} onChangeText={(e) => setName(e)} placeholder="Entrez votre prénom" />
                                </>
                            )
                        }
                    </> :
                    <>
                        <Text style={[styles.bold, { marginBottom: 5 }]}>Adresse</Text>
                        <TextInput autoFocus style={styles.input} onSubmitEditing={() => cityRef.current.focus()} placeholderTextColor="gray" value={address} onChangeText={(e) => setAddress(e)} placeholder="Entrez votre adresse" />
                        <Text style={[styles.bold, { marginBottom: 5, marginTop: 15 }]}>Ville</Text>
                        <TextInput ref={cityRef} style={styles.input} onSubmitEditing={() => postCodeRef.current.focus()} placeholderTextColor="gray" value={city} onChangeText={(e) => setCity(e)} placeholder="Entrez votre ville" />
                        <Text style={[styles.bold, { marginBottom: 5, marginTop: 15 }]}>Code postal</Text>
                        <TextInput ref={postCodeRef} onSubmitEditing={() => {
                            if (address.length < 5) {
                                return alert("L'adresse doit contenir au moins 5 caractères");
                            }

                            if (city.length < 2) {
                                return alert("La ville doit contenir au moins 2 caractères");
                            }

                            if (postCode.length < 5) {
                                return alert("Le code postal doit contenir au moins 5 caractères");
                            }

                            signUpWithNavigation();
                        }} style={styles.input} placeholderTextColor="gray" value={postCode} onChangeText={(e) => setPostCode(e)} placeholder="Entrez votre code postal" />
                    </>
                }


                <TouchableOpacity style={styles.signButton} onPress={() => {
                    if (showAddress) {
                        if (address.length < 5) {
                            return alert("L'adresse doit contenir au moins 5 caractères");
                        }

                        if (city.length < 2) {
                            return alert("La ville doit contenir au moins 2 caractères");
                        }

                        if (postCode.length < 5) {
                            return alert("Le code postal doit contenir au moins 5 caractères");
                        }

                        signUpWithNavigation();
                    } else if (showPwd) {
                        if (email.search("@") == -1 || email.search(".") == -1 || email.length < 6) {
                            return alert("L'email est invalide");
                        } else if (pwd.length < 6) {
                            return alert("Le mot de passe doit contenir au moins 6 caractères");
                        }

                        if (name.length < 2) {
                            return alert("Le prénom doit contenir au moins 2 caractères");
                        }

                        if (lastName.length < 2) {
                            return alert("Le nom doit contenir au moins 2 caractères");
                        }

                        setShowAddress(true);

                    } else {
                        setShowPwd(true);
                    }
                }}>
                    {loading ? <ActivityIndicator color={"white"} /> : <Text style={{ textAlign: "center", color: "white", fontWeight: "600" }}>{showPwd ? "Suivant" : showAddress ? "S'inscrire" : "Suivant"}</Text>}
                </TouchableOpacity>
                <TouchableOpacity onPress={() => navigation.navigate("SignIn")}>
                    <Text style={styles.authLink}>Déjà un compte, se connecter</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}