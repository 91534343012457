import { View, Text, TouchableOpacity } from "react-native"
import { styles } from "../Styles"

export const NumberInput = ({ max, min, setVal, val }) => {

    const up = () => {
        if (val < max) {
            setVal(val + 1)
        }
    }

    const down = () => {
        if (val > min) {
            setVal(val - 1)
        }
    }

    return (
        <View style={[styles.row, { alignItems: "center", width: 45, justifyContent: "space-between", borderRadius: 5, paddingHorizontal: 5 }, styles.shadow]}>
            <Text>{val < 10 ? "0" + val : val}</Text>
            <View>
                <TouchableOpacity onPress={() => up()}>
                    <Text style={{ lineHeight: 12, fontSize: 12, fontWeight: "600" }}>+</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => down()}>
                    <Text style={{ lineHeight: 12, fontSize: 12, fontWeight: "600" }}>-</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}
