import { View, Text, useWindowDimensions, ActivityIndicator, TouchableOpacity } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { NavigationContainer } from "@react-navigation/native";
import { Home } from "./Home/Home";
import { styles } from "./Styles";
import { useEffect, useState } from "react";
import { fetchDoctor, isDoctorSub, listenAuth, supabase } from "./supabase";
import { SignIn } from "./Auth/SignIn";
import { SignUp } from "./Auth/SignUp";
import { AgendaAddRdvModal, AgendaAddTaskModal, AgendaAddTimingModal } from "./Home/Modals/AgendaAddTimingModal";
import { RdvModal } from "./Home/Modals/RdvModal";
import { TaskModal } from "./Home/Modals/TaskModal";
import { AgendaModal } from "./Home/Modals/AgendaModal";
import { PatientModal } from "./Home/Modals/PatientModal";
import { TasksListModal } from "./Home/Modals/TasksListModal";
import { Notification } from "./helpers/Notification";
import { AddRdv } from "./Home/Modals/AddRdv";
import { MissingModal } from "./Home/Modals/MissingModal";

const Stack = createNativeStackNavigator();

const SmallScreen = () => {
    return (
        <View style={styles.center}>
            <Text style={styles.lightText}>Small screen</Text>
        </View>
    )
}

const LoadingScreen = () => {
    return (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "white" }}>
            <ActivityIndicator color={"rgb(18,72,251)"} />
        </View>
    )
}

const PayWall = ({doctor}) => {
    return (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "white" }}>
            <Text style={styles.lightText}>Votre abonnement a expiré, veuillez le renouveler pour continuer à utiliser Medalink.</Text>
            
            <TouchableOpacity style={styles.signButton} onPress={() => {
                window.open(`https://billing.stripe.com/p/login/test_aEU8xcfbM59YepqeUU?prefilled_email=${doctor.email}`, "_blank")
            }}>
                <Text style={[styles.bold, {color: "white", textAlign: "center"}]}>Acceder aux payements</Text>
            </TouchableOpacity>
        </View>
    )
}

export const Navigation = () => {
    
    const windowWidth = useWindowDimensions().width;

    const [session, setSession] = useState({});
    const [loading, setLoading] = useState(true);
    const [isDoctor, setIsDoctor] = useState(false);
    const [doctor, setDoctor] = useState({})   
    const [profilePic, setProfilePic] = useState("");
    const [showPayWall, setShowPayWall] = useState(false);

    const getSession = async () => {
        const { data, error } = await supabase.auth.getSession();
        const { session } = data;

        setSession(session);
    }
    const checkDoctor = async () => {
        if (session && session.user) {
            const docData = await fetchDoctor(session.user.id)
            if (docData[0].verified == false) {
                alert("Votre compte n'est pas encore vérifié pour le moment. Medalink vous appelera dans les plus courts délais pour verifier l'autheticité de votre compte. Aucun client ne peut prendre rendez-vous pour le moment. Merci de votre compréhension.")
            } else {                
                if (docData[0].stripe_key) {
                    const isSub = await isDoctorSub(docData[0].stripe_key); 
                    if (isSub == false) {
                        setShowPayWall(true);
                        alert("Votre abonnement a expiré, veuillez le renouveler pour continuer à utiliser Medalink.");
                    }
                } else {
                    setShowPayWall(true);
                    alert("Votre abonnement a expiré, veuillez le renouveler pour continuer à utiliser Medalink.");
                }
                if (docData.length >= 1) {
                    setIsDoctor(true);
                }
                setProfilePic(docData[0].profile_uri + "?time=" + new Date().getTime());                        
                setDoctor(docData[0]);            
            }
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        getSession();
        listenAuth(setSession);                      
    }, []);    
    
    useEffect(() => {     
        checkDoctor(); 
    }, [session])

    return (
        <NavigationContainer>
            <Stack.Navigator 
                screenOptions={{
                    headerShown: false,
                    title: "Medalink"
                }}
            >
                {
                    loading ? (
                        <Stack.Screen name="Loading" component={LoadingScreen} />                            
                    ) : windowWidth < 1200 ? (
                        <Stack.Screen name="SmallScreen" component={SmallScreen} />
                    ) : showPayWall ? (
                        <Stack.Screen name="PayWall" >
                            {(props) => <PayWall {...props} doctor={doctor} />}
                        </Stack.Screen>
                    ) : session && isDoctor ? (
                        <>
                            <Stack.Screen name="Home">
                                {(props) => <>
                                                <Home {...props} session={session} setDoctor={setDoctor} doctor={doctor} profilePic={profilePic} setProfilePic={setProfilePic} />
                                                <Notification {...props} setDoctor={setDoctor} doctor={doctor} />
                                            </>
                                }
                            </Stack.Screen>                        
                            <Stack.Screen name="AgendaAddTimingModal" options={{ presentation: "transparentModal" }}>
                                {(props) => <AgendaAddTimingModal {...props} doctor={doctor} setDoctor={setDoctor} />}
                            </Stack.Screen>
                            <Stack.Screen name="AgendaAddTaskModal" options={{ presentation: "transparentModal" }}>
                                {(props) => <AgendaAddTaskModal {...props} doctor={doctor} setDoctor={setDoctor} />}
                            </Stack.Screen>
                            <Stack.Screen name="RdvModal" component={RdvModal} options={{presentation: "transparentModal"}} />
                            <Stack.Screen name="TaskModal"options={{ presentation: "transparentModal" }} >
                                {(props) => <TaskModal {...props} doctor={doctor} setDoctor={setDoctor} />}
                            </Stack.Screen>
                            <Stack.Screen name="AgendaModal" options={{ presentation: "transparentModal" }} >
                                {(props) => <AgendaModal {...props} doctor={doctor} setDoctor={setDoctor} />}
                            </Stack.Screen>
                            <Stack.Screen name="AgendaAddRdvModal" component={AgendaAddRdvModal} options={{ presentation: "transparentModal" }} />
                            <Stack.Screen name="PatientModal" options={{ presentation: "transparentModal" }} >
                                {(props) => <PatientModal {...props} doctor={doctor} />}
                            </Stack.Screen>
                            <Stack.Screen name="TasksListModal" options={{ presentation: "transparentModal" }} >
                                {(props) => <TasksListModal {...props} doctor={doctor} />}
                            </Stack.Screen>
                            <Stack.Screen name="AddRdv" options={{ presentation: "transparentModal" }} >
                                {(props) => <AddRdv {...props} doctor={doctor} setDoctor={setDoctor} />}
                            </Stack.Screen>
                            <Stack.Screen name="AbsenceModal" options={{ presentation: "transparentModal" }} >
                                {(props) => <MissingModal doctor={doctor} setDoctor={setDoctor} {...props} />}
                            </Stack.Screen>
                        </>
                    ) : (
                        <>
                            <Stack.Screen name="SignIn" component={SignIn} />
                            <Stack.Screen name="SignUp" component={SignUp} />
                        </>
                    )
                }
            </Stack.Navigator>
        </NavigationContainer>
    )
}