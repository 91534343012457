import { useNavigation } from "@react-navigation/native"
import { Image, Text, TouchableOpacity, View } from "react-native";
import { styles } from "../Styles";

export const Modal = ({ children, title }) => {

    const navigation = useNavigation();

    return (        
        <View style={{ justifyContent: "center", alignItems: "center", flex: 1, position: "fixed", height: "100vh", width: "100vw", top: 0, left: 0, backgroundColor: "transparent" }}>
            <TouchableOpacity activeOpacity={1} onPress={() => navigation.goBack()} style={{ height: "100vh", width: "100vw", left: 0, top: 0, backgroundColor: "rgba(0, 0, 0, 0.2)", position: "fixed" }} />
            <View style={[{ padding: 20, backgroundColor: "white", borderRadius: 8, width: "calc(100vw - 60px)", maxWidth: 400 }]}>
                <TouchableOpacity activeOpacity={0.8} style={{ position: "absolute", top: 10, right: 10, zIndex: 1 }} onPress={() => navigation.goBack()}>
                    <Image source={require("../../assets/icons/close.png")} style={{ height: 35, width: 35 }} />
                </TouchableOpacity>
                <View style={[{ width: "100%" }]}>
                    <View style={{ justifyContent: "center" }}>
                        <Text style={[styles.header, {marginBottom: 15, textAlign: "center" }]}>{title}</Text>
                    </View>
                    {
                        children
                    }
                </View>
            </View>
        </View>
    )
}