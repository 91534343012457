import { useEffect, useState } from "react"
import { Text, View } from "react-native"
import { getClientData, supabase } from "../supabase"
import { styles } from "../Styles"
import { getDateFrenchFormat } from "./functions"

export const Notification = ({ setDoctor, doctor }) => {

    const [showNotif, setShowNotif] = useState(false);
    const [newRdv, setNewRdv] = useState({});

    const updateDoctor = async (r) => {
        var rdv = r;
        rdv.reason = "";
        const client = await getClientData(rdv.user_id);                
        setDoctor((prev) => ({
             ...prev,             
             rdv: [...doctor.rdv, {...rdv, client: client[0]}]             
        }))        
        setNewRdv(rdv);
        setShowNotif(true);        
    }

    useEffect(() => {
        supabase
            .channel('any')
            .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'rdv' }, payload => {                                
                const rdv = payload.new;                                
                updateDoctor(rdv)
            })
            .on('postgres_changes', {event: 'DELETE', schema: 'public', table: 'rdv'}, payload => {
                const id = payload.old.id
                setDoctor((prev) => ({
                    ...prev,
                    rdv: prev.rdv.filter(r => r.id != id)
                }))
            })
            .subscribe()        
    }, [])

    useEffect(() => {
        if (showNotif) {
            setTimeout(() => {
                setShowNotif(false);
            }, 5000)
        }
    }, [showNotif])

    return (
        <View style={{ position: "absolute", top: 20, right: 20, backgroundColor: "rgba(18,72,251,0.15)", borderColor: "rgb(18,72,251)", borderWidth: 2, paddingHorizontal: 20, paddingVertical: 14, borderRadius: 8, zIndex: showNotif ? 1 : -1 }}>
            {
                showNotif &&
                <>
                    <Text style={[styles.bold]}>Un nouveau rendez-vous a été pris !</Text>     
                    <Text style={styles.lightText}>Le {getDateFrenchFormat(newRdv.date)} de {String(newRdv.from).slice(0, 5).replace(":", "h")} à {String(newRdv.to).slice(0, 5).replace(":", "h")}</Text>
                </>
            }
        </View>
    )
}