import { useState } from "react";
import { Modal } from "../../helpers/Modal"
import { useNavigation, useRoute } from "@react-navigation/native";
import { ActivityIndicator, Text, TouchableOpacity, View } from "react-native";
import { NumberInput } from "../../helpers/NumberInput";
import { styles } from "../../Styles";
import { updateEdt } from "../../supabase";

export const AgendaModal = ({ doctor, setDoctor }) => {
    const navigation = useNavigation()
    const route = useRoute()
    const from = route.params.from;
    const to = route.params.to;
    const dayIndex = route.params.dayIndex;
    const rdvIndex = route.params.rdvIndex;

    const [from_h, setFrom_h] = useState(from[0])
    const [from_m, setFrom_m] = useState(from[1])
    const [to_h, setTo_h] = useState(to[0])
    const [to_m, setTo_m] = useState(to[1])

    const [loading, setLoading] = useState(false);

    var dif = (to_h + to_m / 60) - (from_h + from_m / 60)
    var duration_h = Math.floor(dif)
    var duration_m = Number((dif - Math.floor(dif)) * 60).toFixed(0);

    const checkIfTimeFree = async () => {
        if (doctor.availability) {
            setLoading(true);
            for (let i = 0; i < doctor.availability.agenda[dayIndex].length; i++) {
                const rdv = doctor.availability.agenda[dayIndex][i]
                if (i != rdvIndex && doctor.availability.agenda[dayIndex].length > 1) {
                    const f_h = Number(rdv[0].slice(0, 2))
                    const f_m = Number(rdv[0].slice(3, 5))
                    const f = f_h + f_m / 60

                    const t_h = Number(rdv[1].slice(0, 2))
                    const t_m = Number(rdv[1].slice(3, 5))
                    const t = t_h + t_m / 60

                    const current_f = from_h + from_m / 60
                    const current_t = to_h + to_m / 60

                    const currentIn = (current_f > f && current_f < t) || (current_t > f && current_t < t)
                    const currentContain = current_f <= f && current_t >= t

                    if (currentIn || currentContain) {
                        setLoading(false);
                        return alert("Aucune modification possible, le crénneau est déja pris.")
                    }
                }
            }

            const agenda = [...doctor.availability.agenda];
            const newTiming = [`${from_h < 10 ? "0" + from_h : from_h}:${from_m < 10 ? "0" + from_m : from_m}:00`, `${to_h < 10 ? "0" + to_h : to_h}:${to_m < 10 ? "0" + to_m : to_m}:00`]

            agenda[dayIndex][rdvIndex] = newTiming;

            const data = await updateEdt(agenda, doctor.uuid)

            if (data) {
                setDoctor(old => ({
                    ...old,
                    availability: {
                        "agenda": agenda,
                        abs_date: old.availability.abs_date
                    }
                }))
                navigation.goBack()
            }

            setLoading(false)

        }
    }

    return (
        <Modal title={"Modifier l'horaire du rendez-vous"}>
            <View>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text style={{ fontSize: 12 }}>Début : </Text>
                    <NumberInput min={7} max={20} val={from_h} setVal={setFrom_h} />
                    <Text style={{ fontSize: 12 }}> h </Text>
                    <NumberInput min={0} max={59} val={from_m} setVal={setFrom_m} />
                </View>
                <View style={{ flexDirection: "row", alignItems: "center", marginVertical: 10 }}>
                    <Text style={{ fontSize: 12 }}>Fin : </Text>
                    <NumberInput min={7} max={20} val={to_h} setVal={setTo_h} />
                    <Text style={{ fontSize: 12 }}> h </Text>
                    <NumberInput min={0} max={59} val={to_m} setVal={setTo_m} />
                </View>

                <Text style={[styles.lightText ,{ fontSize: 12 }]}>Durée du rendez-vous : {dif > 0 ? `${duration_h}h${duration_m < 10 ? "0" + duration_m : duration_m}` : "0h"}</Text>
                <TouchableOpacity disabled={loading} onPress={() => {
                    if (dif < 1 / 4) {
                        return alert("La plage horraire choisie est trop courte.")
                    }
                    checkIfTimeFree()
                }} style={{ marginTop: 20, justifyContent: "center", alignItems: "center", padding: 10, backgroundColor: "rgb(18,72,251)", borderRadius: 10 }}>
                    {loading ? <ActivityIndicator color={"white"} /> : <Text style={{ color: "white", fontWeight: "600" }}>Modifier l'horaire</Text>}
                </TouchableOpacity>
                <TouchableOpacity disabled={loading} onPress={async () => {
                    if (doctor.availability) {
                        setLoading(true)
                        const agenda = [...doctor.availability.agenda];

                        agenda[dayIndex].splice(rdvIndex, 1)

                        const data = await updateEdt(agenda, doctor.uuid)


                        if (data) {
                            setDoctor(old => ({
                                ...old,
                                availability: {
                                    "agenda": agenda,
                                    abs_date: old.availability.abs_date
                                }
                            }))
                            navigation.goBack()
                        }

                        setLoading(false)

                    }
                }} style={{ justifyContent: "center", alignItems: "center", paddingTop: 10 }}>
                    <Text style={{ color: "rgb(18,72,251)", fontWeight: "600" }}>Supprimer l'horaire</Text>
                </TouchableOpacity>
            </View>

        </Modal>
    )
}