import { ActivityIndicator, Text, TextInput, Touchable, TouchableOpacity, View, ScrollView } from "react-native"
import { Modal } from "../../helpers/Modal"
import { useEffect, useRef, useState } from "react"
import { styles } from "../../Styles"
import { addRdv, getClientFromName, getClientFromPhone } from "../../supabase"
import { calculateDuration, compareTime, getDateFrenchFormat, isDate1GreaterOrEqual, isDateValid } from "../../helpers/functions"
import { useNavigation } from "@react-navigation/native"

const DualButton = ({ setExistingClient, existingClient }) => {
    return (
        <View style={[styles.row, { alignSelf: "center", borderWidth: 1, borderColor: "rgba(18,72,251, 0.15)", borderRadius: 8 }]}>
            <TouchableOpacity onPress={() => setExistingClient(true)} style={[styles.dualButton, existingClient && styles.dualButtonSelected]}>
                <Text style={[styles.bold, { color: existingClient ? "white" : "black" }]}>Déjà un compte</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setExistingClient(false)} style={[styles.dualButton, !existingClient && styles.dualButtonSelected]}>
                <Text style={[styles.bold, { color: !existingClient ? "white" : "black" }]}>Pas encore de compte</Text>
            </TouchableOpacity>
        </View>
    )
}

export const AddRdv = ({ doctor, setDoctor }) => {

    const today = new Date();
    
    const navigation = useNavigation()

    const monthRef = useRef();
    const yearRef = useRef();

    const [existingClient, setExistingClient] = useState(true);
    const [phoneSearch, setPhoneSearch] = useState(true);

    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("")
    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState(null);
    const [selectDate, setSelectDate] = useState(false);
    const [selectRdv, setSelectRdv] = useState(false);
    const [selectReason, setSelectReason] = useState(false);
    const [reason, setReason] = useState("");
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState(today.getFullYear());
    const [freeTiming, setFreeTiming] = useState([]);
    const [timing, setTiming] = useState({})


    const searchClient = async () => {
        if (phoneSearch) {
            if (phone.length >= 10) {
                setLoading(true)
                const data = await getClientFromPhone(phone)
                if (data) {
                    setClient(data);
                } else {
                    setClient({})
                }
                setLoading(false)
            } else {
                alert("Le numéro de téléphone doit contenir au moins 10 chiffres")
            }
        } else {
            if (name.length < 3 && lastname.length < 3) {
                return alert("Vous devez ajouter au moins 3 lettres pour un des deux champs.")
            }
            setLoading(true);
            const data = await getClientFromName(name, lastname)
            if (data) {
                setClient(data);            
            } else {
                setClient({})
            }
            setLoading(false);
        }
    }

    const checkInputs = () => {

        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            return alert("Les champs doivent être des nombres.")
        }

        if (day < 1 || day > 31) {
            return alert("Le jour doit être compris entre 1 et 31.");
        }

        if (month < 1 || month > 12) {
            return alert("Le mois doit être compris entre 1 et 12.");
        }

        if (!isDate1GreaterOrEqual(year, month, day, today.getFullYear(), today.getMonth() + 1, today.getDate())) {
            return alert("La date doit être dans le futur.");
        }

        if (!isDateValid(year, month, day)) {
            return alert("La date que vous avez entrée n'est pas valide.")
        } 
        
        return true;
    }

    const getFreeTimingOfSpecificDay = () => {        
        const dayRdvs = doctor.rdv.filter((rdv) => rdv.date == `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`);
        const dayTimings = doctor.availability.agenda[(new Date(year, month - 1, day).getDay() + 6) % 7];

        var sortedDates = [];
        for (let i = 0; i < dayTimings.length; i++) {
            const from = dayTimings[i][0];
            const to = dayTimings[i][1];
            const rdv = dayRdvs.find((rdv) => rdv.from == from || rdv.to == to);
            if (from != "00:00:00" && to != "00:00:00" && !rdv) {                
                sortedDates.push({ from: from, to: to })
            }

        }

        setFreeTiming(sortedDates.sort((a, b) => compareTime(a.from, b.from)))

    }

    return (
        <Modal title={"Ajouter un rendez-vous"}>
            {
                !selectDate ?
            
                    <>
                        <Text style={{ marginBottom: 10 }}>Est ce que votre client a :</Text>
                        <DualButton setExistingClient={setExistingClient} existingClient={existingClient} />
                        {
                            existingClient ?
                                <>
                                    {
                                        phoneSearch ?
                                            <>
                                                <Text style={[styles.bold, { marginBottom: 5, marginTop: 10 }]}>Téléphone</Text>
                                                <TextInput onSubmitEditing={async () => await searchClient()} style={styles.input} value={phone} onChangeText={(e) => setPhone(e)} placeholder="Entrez le téléphone du patient" placeholderTextColor="lightgray" />
                                            </>
                                            :
                                            <>
                                                <Text style={[styles.bold, { marginBottom: 5, marginTop: 15 }]}>Nom du patient</Text>
                                                <TextInput style={styles.input} value={lastname} onChangeText={(e) => setLastname(e)} />
                                                <Text style={[styles.bold, { marginBottom: 5, marginTop: 10 }]}>Prénom du patient</Text>
                                                <TextInput style={styles.input} value={name} onChangeText={(e) => setName(e)}  onSubmitEditing={async () => await searchClient() }/>
                                            </>
                                    }
                                    <TouchableOpacity onPress={() => setPhoneSearch(!phoneSearch)}>
                                        <Text style={[{ marginTop: 10 }, styles.authLink, styles.bold, styles.blueFont]}>{phoneSearch ? "Rechercher avec nom et prénom" : "Rechercher avec l'email"}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.signButton} onPress={async () => {
                                        await searchClient();
                                    }}>
                                        <Text style={[styles.bold, { color: "white", textAlign: "center" }]}>Rechercher</Text>
                                    </TouchableOpacity>
                                    <View>
                                        {
                                            loading && <ActivityIndicator size={"small"} color="rgb(18,72,251)" />
                                        }
                                        {
                                            client != null && !loading ?

                                                Object.keys(client).length >= 1 ?
                                                    <>
                                                        <Text style={[styles.bold, { marginTop: 10 }]}>Nom</Text>
                                                        <Text style={[styles.lightText, { marginTop: 5 }]}>{client.lastname}</Text>
                                                        <Text style={[styles.bold, { marginTop: 10 }]}>Prénom</Text>
                                                        <Text style={[styles.lightText, { marginTop: 5 }]}>{client.name}</Text>
                                                        {
                                                            client.email && <>
                                                                <Text style={[styles.bold, { marginTop: 10 }]}>Email</Text>
                                                                <Text style={[styles.lightText, { marginTop: 5 }]}>{client.email}</Text>
                                                            </>
                                                        }                                            
                                                        {
                                                            client.birthdate && <>
                                                                <Text style={[styles.bold, { marginTop: 10 }]}>Age</Text>
                                                                <Text style={[styles.lightText, { marginTop: 5 }]}>{getDateFrenchFormat(client.birthdate)} ({calculateDuration(client.birthdate, new Date()).years} ans)</Text>
                                                            </>
                                                        }
                                                        <TouchableOpacity style={styles.signButton} onPress={async () => {
                                                            setSelectDate(true);
                                                        }}>
                                                            <Text style={[styles.bold, { color: "white", textAlign: "center" }]}>Choisir cet utilisateur</Text>
                                                        </TouchableOpacity>
                                                    </>
                                                    :
                                                    <Text style={[styles.lightText, { textAlign: "center", marginTop: 10 }]}>Aucun patient trouvé avec {phoneSearch ? "ce numéro de téléphone" : "ce nom et prénom"}</Text>
                                                :
                                                null
                                        }
                                    </View>
                                </>
                                : 
                                <>
                                    <Text style={[styles.bold, {textAlign: "center", marginTop: 20, marginBottom: 5}]}>Il est impossible de créer un rendez-vous avec un client qui n'a pas encore rejoint la plateforme. Veuillez lui demander de se créer un compte sur l'application ou le site web !</Text>
                                    <TouchableOpacity style={styles.signButton}>
                                        <Text style={[styles.bold, {color: "white", textAlign: "center"}]}>Inviter le patient sur medalink par email</Text>
                                    </TouchableOpacity>
                                </>
                        }
                    </>
                    : selectRdv ? selectReason ?

                    <>
                        <Text style={{marginBottom: 10}}>Pour quelle raison ?</Text>
                        <TextInput style={styles.input} value={reason} onChangeText={(e) => setReason(e)} onSubmitEditing={async () => {
                            setLoading(true)
                            const data = await addRdv(doctor.uuid, client.uuid, `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`, timing.from, timing.to, reason)
                            if (data) {                                
                                navigation.goBack()
                            }
                            setLoading(false)
                        }} />
                        <TouchableOpacity style={styles.signButton} onPress={async () => {
                            setLoading(true)
                            await addRdv()
                            navigation.goBack()
                            setLoading(false)                            
                        }}>
                            {loading ? <ActivityIndicator color={"white"} /> : <Text style={[styles.bold, {color: "white", textAlign: "center"}]}>Créer le rendez-vous</Text>}
                        </TouchableOpacity> 
                    </>

                    :

                    <>
                        <Text style={{ marginBottom: 10 }}>Choisissez un créneau pour le {day}/{month}/{year}</Text>
                        {
                            freeTiming.length >= 1 ? 
                                <ScrollView style={{ maxHeight: 200 }}>
                                    {
                                        freeTiming.map((timing, i) => {
                                            return (
                                                <TouchableOpacity onPress={() => {
                                                    setSelectReason(true);
                                                    setTiming(timing);
                                                }} key={i} style={{ marginBottom: i == freeTiming.length - 1 ? 0 : 10, paddingVertical: 10, paddingHorizontal: 15, borderRadius: 8, backgroundColor: "rgba(18,72,251,0.15)", alignSelf: "center"}}>
                                                    <Text>De <Text style={styles.bold}>{String(timing.from).slice(0, 5).replace(":", "h")}</Text> à <Text style={styles.bold}>{String(timing.to).slice(0, 5).replace(":", "h")}</Text></Text>
                                                </TouchableOpacity>
                                            )
                                        })
                                    }
                                </ScrollView>
                                :
                                <Text style={[styles.lightText, styles.bold]}>Plus aucun crénau disponible à cette date...</Text>
                        }
                    </>
                    :
                    <>
                        <Text style={{ marginBottom: 10 }}>Choisissez une date à laquelle ajouter le rdv</Text>
                        <View style={[styles.row, styles.spaceBT, { alignItems: "center" }]}>
                            <TextInput onSubmitEditing={() => monthRef.current.focus()} style={[styles.input, { minWidth: 20 }]} placeholderTextColor="gray" placeholder="Jour" value={day} onChangeText={(e) => setDay(e)} />
                            <Text style={[styles.title, { marginHorizontal: 5 }]}>/</Text>

                            <TextInput ref={monthRef} onSubmitEditing={() => yearRef.current.focus()} style={[styles.input, { minWidth: 20 }]} placeholderTextColor="gray" placeholder="Mois" value={month} onChangeText={(e) => setMonth(e)} />
                            <Text style={[styles.title, { marginHorizontal: 5 }]}>/</Text>

                            <TextInput ref={yearRef} onSubmitEditing={() => {
                                if (checkInputs()) {
                                    setSelectRdv(true)
                                    getFreeTimingOfSpecificDay();
                                }
                            }} style={[styles.input, { minWidth: 40 }]} placeholderTextColor="gray" placeholder="Année" value={year} onChangeText={(e) => setYear(e)} />
                        </View>
                        <TouchableOpacity style={styles.signButton} onPress={() => {
                            if (checkInputs()) {
                                setSelectRdv(true);
                                getFreeTimingOfSpecificDay();
                            }
                        }}>
                            <Text style={[styles.bold, {color: "white", textAlign: "center"}]}>Suivant</Text>
                        </TouchableOpacity>
                    </>
            }
        </Modal>
    )
}