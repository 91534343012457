import { View, Text, TextInput, TouchableOpacity, ActivityIndicator } from "react-native"
import { styles } from "../Styles"
import { useEffect, useState } from "react"
import { useNavigation, useRoute } from "@react-navigation/native";
import { signIn } from "../supabase";

export const SignIn = () => {

    const navigation = useNavigation();
    const route = useRoute();
    const e = route.params?.e;

    const [email, setEmail] = useState("");
    const [pwd, setPwd] = useState("");

    const [showPwd, setShowPwd] = useState(false);
    const [loading, setLoading] = useState(false);    

    useEffect(() => {
        if (e) {
            setEmail(e)
        }
    }, [e])

    return (
        <View style={styles.center}>
            <View>
                <Text style={[styles.title, {marginBottom: 20, textAlign: "center"}]}>Se connecter</Text>
                <Text style={[styles.bold, {marginBottom: 5}]}>Email</Text>
                <TextInput onSubmitEditing={() => setShowPwd(true)} style={styles.input} placeholderTextColor="gray" value={email} onChangeText={(e) => setEmail(e)} placeholder="Entrez votre email" />

                {
                    showPwd && (
                        <>
                            <Text style={[styles.bold, {marginBottom: 5, marginTop: 15}]}>Mot de passe</Text>
                            <TextInput onSubmitEditing={async () => {
                                setLoading(true);
                                await signIn(email, pwd)
                                setLoading(false);
                            }} autoFocus style={styles.input} secureTextEntry placeholderTextColor="lightgray" value={pwd} onChangeText={(e) => setPwd(e)} placeholder="Entrez votre mot de passe" />
                        </>
                    )
                }


                <TouchableOpacity disabled={loading} style={styles.signButton} onPress={async () => {
                    if (showPwd) {
                        setLoading(true);
                        await signIn(email, pwd);
                        setLoading(false);
                    } else {
                        setShowPwd(true);
                    }
                }}>
                    {loading ? <ActivityIndicator color={"white"} /> : <Text style={{ textAlign: "center", color: "white", fontWeight: "600" }}>{showPwd ? "Se connecter" : "Suivant"}</Text>}
                </TouchableOpacity>
                <TouchableOpacity disabled={loading} onPress={() => navigation.navigate("SignUp")}>
                    <Text style={styles.authLink}>Pas encore de compte, s'inscrire</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}