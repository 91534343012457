import { Text, View, ScrollView, TouchableOpacity, Image } from "react-native"
import { styles } from "../Styles"
import { useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { days, getName } from "../helpers/functions";
import CheckBox from "expo-checkbox";

const ModifyAgenda = ({ doctor }) => {

    const [agenda, setAgenda] = useState([]);

    const navigation = useNavigation()

    useEffect(() => {
        if (doctor.availability) {
            setAgenda(doctor.availability.agenda)
        }
    }, [doctor.availability]);

    return (
        <View>
            <View style={styles.row}>
                <View style={{ paddingVertical: 25 }}>
                    {
                        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((el, i) => {
                            return (
                                <View key={i} style={{ height: 100, justifyContent: "center" }}>
                                    <View style={{ position: "absolute", width: "calc(100vw - 210px - 80px - 70px)", maxWidth: 1600 - 210 - 80 - 70, height: 1, backgroundColor: "lightgray", left: 70, opacity: 0.3 }} />
                                    <Text style={{ fontWeight: "600", width: 70, textAlign: "center" }}>{7 + i}</Text>
                                </View>
                            )
                        })
                    }
                </View>
                <View style={{ flexDirection: "row", justifyContent: "space-between", width: "calc(100vw - 210px - 80px - 70px)", maxWidth: 1600 - 210 - 80 - 70 }}>
                    {
                        agenda.length != 0 && agenda.map((day, i) => {
                            return (
                                <View key={i} style={{ alignItems: "center", width: "calc((100vw - 210px - 80px - 70px - 7*20px) / 7)", maxWidth: (1600 - 70 - 210 - 80 - 7 * 20) / 7 }}>
                                    <Text style={{ marginTop: 20, fontWeight: "600" }}>{(days[(i + 1) % 7]).slice(0, 3)}</Text>
                                    {
                                        day.map((rdv, j) => {
                                            const from_h = Number(rdv[0].slice(0, 2))
                                            const from_m = Number(rdv[0].slice(3, 5))
                                            const from = from_h + from_m / 60

                                            const to_h = Number(rdv[1].slice(0, 2))
                                            const to_m = Number(rdv[1].slice(3, 5))
                                            const to = to_h + to_m / 60

                                            const offset = (from - 6) * 100 + 25 - 50
                                            const h = (to - from) * 100

                                            if (from == to) {
                                                return null;
                                            }

                                            var dif = (to_h + to_m / 60) - (from_h + from_m / 60)
                                            var duration_h = Math.floor(dif)
                                            var duration_m = Number((dif - Math.floor(dif)) * 60).toFixed(0)

                                            return (
                                                <TouchableOpacity onPress={() => navigation.navigate("AgendaModal", { "from": [from_h, from_m], "to": [to_h, to_m], "dayIndex": i, "rdvIndex": j })} key={j} style={[{ width: "calc((100vw - 210px - 80px - 70px - 7*20px) / 7)", maxWidth: (1600 - 70 - 210 - 80 - 7 * 20) / 7, backgroundColor: "rgba(139,18,251, 0.15)", height: h - 4, position: "absolute", left: 0, top: offset + 2, borderRadius: 10, justifyContent: "center", alignItems: "center" }]}>
                                                    <Text style={{ fontWeight: "600", fontSize: 12 }}>De {Math.floor(from)}h{from_m < 10 ? "0" + from_m : from_m} à {Math.floor(to)}h{to_m < 10 ? "0" + to_m : to_m}</Text>
                                                    {
                                                        to - from >= 0.5 &&
                                                        <Text style={{ fontSize: 12, color: "gray", fontWeight: "600" }}>Durée : {duration_h}h{duration_m < 10 ? "0" + duration_m : duration_m}</Text>
                                                    }
                                                </TouchableOpacity>
                                            )
                                        })
                                    }
                                </View>
                            )
                        })
                    }
                </View>
            </View>
        </View>
    )
}

export const Agenda = ({ doctor }) => {

    const [agenda, setAgenda] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [modify, setModify] = useState(false);

    const [showAgenda, setShowAgenda] = useState(true);
    const [showTasks, setShowTasks] = useState(true);

    useEffect(() => {
        var dates = []
        if (doctor.rdv) {
            setAgenda([])
            var date = new Date();

            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`;
            dates.push(formattedDate)

            for (let i = 0; i < 6; i++) {
                date.setDate(date.getDate() + 1);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const day = String(date.getDate()).padStart(2, "0");
                const formattedDate = `${year}-${month}-${day}`;
                dates.push(formattedDate)
            }


            const rdvs = doctor.rdv
            for (let i = 0; i < dates.length; i++) {
                const d = dates[i];
                const rdvs_of_day = rdvs.filter(rdv => rdv.date == d);
                setAgenda(prev => [...prev, rdvs_of_day])
            }
        }

        dates = []
        if (doctor.tasks) {
            setTasks([])
            var date = new Date();

            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`;
            dates.push(formattedDate)

            for (let i = 0; i < 6; i++) {
                date.setDate(date.getDate() + 1);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const day = String(date.getDate()).padStart(2, "0");
                const formattedDate = `${year}-${month}-${day}`;
                dates.push(formattedDate)
            }


            const tasks = doctor.tasks
            const combinedTasks = [];
            for (let i = 0; i < dates.length; i++) {
                const d = dates[i];
                const tasks_of_day = tasks.filter(task => task.date == d);
                combinedTasks.push(tasks_of_day)
            }
            setTasks(combinedTasks)
        }
    }, [doctor])

    const navigation = useNavigation()

    return (
        <ScrollView style={[styles.page, { paddingRight: 20 }]} showsVerticalScrollIndicator={false}>
            <Text style={[styles.title]}>Agenda</Text>
            <Text style={[styles.lightText, { marginTop: 5 }]}>Consultez et modifiez votre agenda</Text>
            <View style={[styles.row, styles.spaceBT, {alignItems: "center"} ]}>
                <TouchableOpacity onPress={() => setModify(!modify)} >
                    <Text style={{ color: "rgb(18,72,251)", fontWeight: "600" }}>{modify ? "Voir son emploi du temps" : "Modifier ses horaires"}</Text>
                </TouchableOpacity>
                {
                    modify ?
                        <View style={styles.row}>
                            <TouchableOpacity activeOpacity={0.8} onPress={() => navigation.navigate("AgendaAddRdvModal", { agenda })} style={[styles.row, { height: 35, marginVertical: 10, padding: 10, width: 180, justifyContent: "center", alignItems: "center", marginLeft: 15, backgroundColor: "rgb(18,72,251)", borderRadius: 8 }]}>
                                <Image source={require("../../assets/icons/plus.png")} style={{ height: 25, width: 25, marginRight: 5, tintColor: "white" }} />
                                <Text style={{ color: "white", fontWeight: "600" }}>Ajouter un horraire</Text>
                            </TouchableOpacity>
                            <TouchableOpacity activeOpacity={0.8} onPress={() => navigation.navigate("AbsenceModal")} style={[styles.row, { height: 35, marginVertical: 10, padding: 10, width: 330, justifyContent: "center", alignItems: "center", marginLeft: 15, backgroundColor: "rgb(18,72,251)", borderRadius: 8 }]}>
                                <Image source={require("../../assets/icons/plus.png")} style={{ height: 25, width: 25, marginRight: 5, tintColor: "white" }} />
                                <Text style={{ color: "white", fontWeight: "600" }}>Ajouter / Modifier une période d'absence</Text>
                            </TouchableOpacity>
                        </View>
                        :
                        <View style={styles.row}>
                            <TouchableOpacity activeOpacity={0.8} onPress={() => navigation.navigate("TasksListModal")} style={[styles.row, { height: 35, marginVertical: 10, padding: 10, width: 160, justifyContent: "center", alignItems: "center", marginLeft: 15, backgroundColor: "rgb(18,72,251)", borderRadius: 8 }]}>
                                <Image source={require("../../assets/icons/check.png")} style={{ height: 25, width: 25, marginRight: 5, tintColor: "white" }} />
                                <Text style={{ color: "white", fontWeight: "600" }}>Voir ses tâches</Text>
                            </TouchableOpacity>
                            <TouchableOpacity activeOpacity={0.8} onPress={() => navigation.navigate("AgendaAddTaskModal", { agenda })} style={[styles.row, { height: 35, marginVertical: 10, padding: 10, width: 180, justifyContent: "center", alignItems: "center", marginLeft: 15, backgroundColor: "rgb(18,72,251)", borderRadius: 8 }]}>
                                <Image source={require("../../assets/icons/plus.png")} style={{ height: 25, width: 25, marginRight: 5, tintColor: "white" }} />
                                <Text style={{ color: "white", fontWeight: "600" }}>Ajouter une tâche</Text>
                            </TouchableOpacity>
                            <TouchableOpacity activeOpacity={0.8} onPress={() => navigation.navigate("AddRdv")} style={[styles.row, { height: 35, marginVertical: 10, padding: 10, width: 220, justifyContent: "center", alignItems: "center", marginLeft: 15, backgroundColor: "rgb(18,72,251)", borderRadius: 8 }]}>
                                <Image source={require("../../assets/icons/plus.png")} style={{ height: 25, width: 25, marginRight: 5, tintColor: "white" }} />
                                <Text style={{ color: "white", fontWeight: "600" }}>Ajouter un rendez-vous</Text>
                            </TouchableOpacity>
                        </View>
                }
            </View>
            {!modify ?
                // default agenda
                <>
                    <View style={styles.row}>
                        <CheckBox value={showAgenda} onValueChange={() => setShowAgenda(!showAgenda)} color={"rgb(18,72,251)"} />
                        <TouchableOpacity activeOpacity={0.8} onPress={() => setShowAgenda(!showAgenda)}>
                            <Text style={[styles.bold, { marginLeft: 5, marginBottom: 5 }]}>Afficher les rendez-vous</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.row}>
                        <CheckBox value={showTasks} onValueChange={() => setShowTasks(!showTasks)} color={"rgb(251,147,18)"} />
                        <TouchableOpacity activeOpacity={0.8} onPress={() => setShowTasks(!showTasks)}>
                            <Text style={[styles.bold, { marginLeft: 5, marginBottom: 5 }]}>Afficher les tâches</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={[styles.row, {marginTop: 10}]}>
                        <View style={{ paddingVertical: 25 }}>
                            {
                                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((el, i) => {
                                    return (
                                        <View key={i} style={{ height: 100, justifyContent: "center" }}>
                                            <View style={{ position: "absolute", width: "calc(100vw - 210px - 80px - 70px)", maxWidth: 1600 - 210 - 80 - 70, height: 1, backgroundColor: "lightgray", left: 70, opacity: 0.3 }} />
                                            <Text style={{ fontWeight: "600", width: 70, textAlign: "center" }}>{7 + i}</Text>
                                        </View>
                                    )
                                })
                            }
                        </View>
                        <View style={{ flexDirection: "row", justifyContent: "space-between", width: "calc(100vw - 210px - 80px - 70px)", maxWidth: 1600 - 210 - 80 - 70 }}>
                            {
                                agenda.length != 0 && agenda.map((day, i) => {

                                    const d = new Date().getDay() + i;
                                    const d2 = new Date().getDate() + i;

                                    return (
                                        <View key={i} style={{ alignItems: "center", width: "calc((100vw - 210px - 80px - 70px - 7*20px) / 7)", maxWidth: (1600 - 70 - 210 - 80 - 7 * 20) / 7 }}>
                                            <Text style={{ marginTop: 20, fontWeight: "600" }}>{days[d % 7]} {d2}</Text>
                                            {
                                                day.map((rdv, j) => {
                                                    const from_h = Number(rdv.from.slice(0, 2))
                                                    const from_m = Number(rdv.from.slice(3, 5))
                                                    const from = from_h + from_m / 60

                                                    const to_h = Number(rdv.to.slice(0, 2))
                                                    const to_m = Number(rdv.to.slice(3, 5))
                                                    const to = to_h + to_m / 60

                                                    const offset = (from - 6) * 100 + 25 - 50
                                                    const h = (to - from) * 100

                                                    if (from == to) {
                                                        return null;
                                                    }

                                                    if (!showAgenda) {
                                                        return null;
                                                    }

                                                    return (
                                                        <TouchableOpacity onPress={() => navigation.navigate("RdvModal", { rdv: rdv })} key={j} style={[{ width: "calc((100vw - 210px - 80px - 70px - 7*20px) / 7)", maxWidth: (1600 - 70 - 210 - 80 - 7 * 20) / 7, backgroundColor: "rgba(18,72,251, 0.15)", height: h - 4, position: "absolute", left: 0, top: offset + 2, borderRadius: 8, justifyContent: "center", alignItems: "center" }]}>
                                                            <Text style={{ fontWeight: "600", fontSize: 12 }}>{Math.floor(from)}h{from_m < 10 ? "0" + from_m : from_m} à {Math.floor(to)}h{to_m < 10 ? "0" + to_m : to_m}</Text>
                                                            {
                                                                to - from >= 0.5 &&
                                                                <Text style={{ fontSize: 12, color: "gray", fontWeight: "600", textAlign: "center" }}>{getName(rdv.client.name, rdv.client.lastname).replace(" ", "\n")}</Text>
                                                            }
                                                        </TouchableOpacity>
                                                    )
                                                })
                                            }
                                        </View>
                                    )
                                })
                            }
                            <View style={{ flexDirection: "row", justifyContent: "space-between", width: "calc(100vw - 210px - 80px - 70px)", maxWidth: 1600 - 210 - 80 - 70, position: "absolute", zIndex: 1, left: 0, top: 0 }}>
                                {
                                    tasks.length != 0 && tasks.map((day, i) => {
                                        return (
                                            <View key={i} style={{ alignItems: "center", width: "calc((100vw - 210px - 80px - 70px - 7*20px) / 7)", maxWidth: (1600 - 70 - 210 - 80 - 7 * 20) / 7 }}>
                                                {
                                                    day.map((rdv, j) => {
                                                        const from_h = Number(rdv.from.slice(0, 2))
                                                        const from_m = Number(rdv.from.slice(3, 5))
                                                        const from = from_h + from_m / 60

                                                        const to_h = Number(rdv.to.slice(0, 2))
                                                        const to_m = Number(rdv.to.slice(3, 5))
                                                        const to = to_h + to_m / 60

                                                        const offset = (from - 6) * 100 + 25 - 50
                                                        const h = (to - from) * 100

                                                        if (from == to) {
                                                            return null;
                                                        }

                                                        if (!showTasks) {
                                                            return null;
                                                        }

                                                        return (
                                                            <TouchableOpacity onPress={() => navigation.navigate("TaskModal", { task: rdv })} key={j} style={[{ width: "calc((100vw - 210px - 80px - 70px - 7*20px) / 7)", paddingHorizontal: 5, maxWidth: (1600 - 70 - 210 - 80 - 7 * 20) / 7, backgroundColor: "rgba(251,147,18, 0.15)", height: h - 4, position: "absolute", left: 0, top: offset + 2, borderRadius: 8, justifyContent: "center", alignItems: "center" }]}>
                                                                <Text style={{ fontWeight: "600", fontSize: 12 }}>{Math.floor(from)}h{from_m < 10 ? "0" + from_m : from_m} à {Math.floor(to)}h{to_m < 10 ? "0" + to_m : to_m}</Text>
                                                                {
                                                                    to - from >= 0.5 &&
                                                                    <Text numberOfLines={1} style={{ fontSize: 12, color: "gray", fontWeight: "600" }}>{rdv.title}</Text>
                                                                }
                                                            </TouchableOpacity>
                                                        )
                                                    })
                                                }
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        </View>
                    </View>
                </>
                :
                // modify agenda
                <ModifyAgenda doctor={doctor} />
            }
        </ScrollView>
    )
}