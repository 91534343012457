export const getName = (name, lastname) => {
    return `${String(lastname).toUpperCase()} ${String(name).at(0).toUpperCase() + String(name).slice(1).toLowerCase()}`
}

export const calculateDuration = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const startYear = start.getFullYear();
    const startMonth = start.getMonth();

    const endYear = end.getFullYear();
    const endMonth = end.getMonth();

    var yearsDiff = endYear - startYear;
    var monthsDiff = endMonth - startMonth;

    // Adjust for negative months difference
    if (monthsDiff < 0) {
        yearsDiff--;
        monthsDiff += 12;
    }

    return { years: yearsDiff, months: monthsDiff };
}

export const getDateFrenchFormat = (date) => {
    return `${date.slice(8, 10)}/${date.slice(5, 7)}/${date.slice(0, 4)}`
}

export const getDocument = (setFile) => {

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/jpeg, image/jpg, image/png';
    input.multiple = false;
    input.onchange = _ => {
        if (input.files && input.files[0]) {
            let file = input.files[0];
            if (file.size <= 50 * 1024) {
                setFile(input.files[0]);
            } else {
                alert("Votre photo est trop lourde pour être envoyée.");
            }
        }
    };
    input.click();
}

export const isDate1GreaterOrEqual = (year1, month1, day1, year2, month2, day2) => {

    if (year1 > year2) {
        return true;
    } else if (year1 == year2 && month1 > month2) {
        return true;
    } else if (year1 == year2 && month1 == month2 && day1 >= day2) {
        return true;
    } else {
        return false;
    }

}

export const isDateValid = (year, month, day) => {
    const date = new Date(year, month - 1, day);

    return (date.getFullYear() == year && date.getMonth() + 1 == month && date.getDate() == day);
}

export const compareTime = (time1, time2) => {    
    const h1 = Number(time1.slice(0, 2));
    const m1 = Number(time1.slice(3, 5));

    const h2 = Number(time2.slice(0, 2));
    const m2 = Number(time2.slice(3, 5));

    if (h1 > h2) {
        return 1;
    } else if (h1 == h2 && m1 >= m2) {
        return 1;
    } else {
        return -1;
    }
}

export const months = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
export const days = ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"]
export const MONTHS = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Decembre"]
export const DAYS = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"]