import { View, Text, TouchableOpacity, Image, ActivityIndicator } from "react-native"
import { styles } from "../Styles";
import { useNavigation } from "@react-navigation/native";
import { getName } from "../helpers/functions"

export const NavBar = ({ doctor, setSelectedIndex, selectedIndex, profilePic }) => {

    const navigation = useNavigation();

    const tabs = [
        {
            title: "Accueil",
            icon: require("../../assets/icons/home.png"),
        },
        {
            title: "Agenda",
            icon: require("../../assets/icons/calendar.png"),
        },
        {
            title: "Patients",
            icon: require("../../assets/icons/clients.png"),
        },
        {
            title: "Paramètres",
            icon: require("../../assets/icons/settings.png"),
        },
        {
            title: "Payements",
            icon: require("../../assets/icons/payments.png"),
        }
    ]    

    return (
        <View style={[styles.navbar, styles.spaceBT]}>
            <View>
                <Text
                    style={[
                        styles.blueFont,
                        styles.title,
                        { marginBottom: 30, marginTop: 20 }
                    ]}>Medalink</Text>
                {
                    tabs.map((tab, i) => {
                        const cond = tab.title == "Agenda" || tab.title == "Patients"
                        const selected = selectedIndex == i
                        return (
                            <TouchableOpacity onPress={() => i != 4 ? setSelectedIndex(i) : window.open(`https://billing.stripe.com/p/login/test_aEU8xcfbM59YepqeUU?prefilled_email=${doctor.email}`, "_blank")} key={i} style={[styles.tab, styles.row, selected && styles.selectedTab ]}>
                                <Image source={tab.icon} style={[styles.tabIcon, { height: cond ? 30 : 35, width: cond ? 30 : 35, marginLeft: cond && 2, tintColor: selected && "#1248FB"  }]} />
                                <Text style={[styles.bold, {marginLeft: cond && 5 }]}>{tab.title}</Text>
                            </TouchableOpacity>
                        )
                    })
                }
                <TouchableOpacity style={[styles.tab, styles.row, { marginTop: 30 }]} onPress={() => navigation.navigate("AddRdv")}>
                    <Image source={require("../../assets/icons/plus.png")} style={[styles.tabIcon, {height: 28, width: 28, tintColor: "#1248FB"}]} />
                    <Text style={[styles.blueFont, styles.bold]}>Ajouter un rdv</Text>
                </TouchableOpacity>
            </View>
            <TouchableOpacity onPress={() => setSelectedIndex(3)} style={[styles.row, { alignItems: "center" }]}>
                {
                    doctor.name && doctor.lastname &&
                    <>
                        {doctor.profile_uri ?
                            <Image style={styles.ppImage} source={{uri: profilePic}} />
                            :
                            <View style={styles.pp} />
                        }
                    </>

                }
                {
                    doctor.name && doctor.lastname ?
                        <View style={{ marginLeft: 10 }}>
                            <Text style={styles.bold}>{doctor.name}</Text>
                            {doctor.spe ? <Text style={styles.lightText} >{doctor.spe}</Text> : <Text style={styles.lightText} >Spécialité</Text> }
                        </View>
                        :
                        <ActivityIndicator color={"rgb(18,72,251)"} />
                }
            </TouchableOpacity>
        </View>
    )
}