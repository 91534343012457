import { View, Text, ScrollView, TextInput, TouchableOpacity, Image } from 'react-native';
import { styles } from '../Styles';
import { useEffect, useRef, useState } from 'react';
import { logout, uploadProfilePicture } from '../supabase';
import { useHover } from "@react-native-aria/interactions";
import { useNavigation } from '@react-navigation/native';

const TextField = ({ data, uploadNewData }) => {

    const { name, value, setValue } = data;

    return (
        <View style={{ marginBottom: 10 }}>
            <Text style={[styles.inputTitle, { marginTop: 0 }]}>{name}</Text>
            <TextInput style={styles.input} value={value} onChangeText={(e) => setValue(e)} onSubmitEditing={() => uploadNewData()} />
        </View>
    )
}

export const Settings = ({ doctor, setDoctor, profilePic, setProfilePic }) => {

    const navigation = useNavigation();

    const [name, setName] = useState(doctor.name)
    const [lastname, setLastname] = useState(doctor.lastname)
    const [spe, setSpe] = useState(doctor.spe)
    const [address, setAddress] = useState(doctor.address)
    const [city, setCity] = useState(doctor.city)
    const [postcode, setPostcode] = useState(doctor.postcode)

    const [loading, setLoading] = useState(false);

    const fileds = [
        {
            func: "name",
            name: "Prénom",
            value: name,
            setValue: setName,
        },
        {
            func: "lastname",
            name: "Nom",
            value: lastname,
            setValue: setLastname,
        },
        {
            func: "address",
            name: "Adresse",
            value: address,
            setValue: setAddress,
        },
        {
            func: "city",
            name: "Ville",
            value: city,
            setValue: setCity,
        }, {
            func: "postcode",
            name: "Code postal",
            value: postcode,
            setValue: setPostcode,
        }
    ]

    const uploadNewData = async () => {
        const obj = {}
        for (let i = 0; i < fileds.length; i++) {
            const field = fileds[i];
            const { func, value } = field;
            if (value != doctor[func]) {
                obj[func] = value;
            }
        }

        if (Object.keys(obj).length > 0) {
            setLoading(true)
            const data = await modifyDocColumn(doctor.uuid, obj)
            if (data) {
                setDoctor(old => ({
                    ...old,
                    ...data,
                }))
                setLoading(false);
            }
        }
    }

    const ref = useRef()
    const { isHovered, hoverProps } = useHover({}, ref);

    const [file, setFile] = useState({})
    const [fileLoading, setFileLoading] = useState(false);

    useEffect(() => {
        const upload = async () => {
            if (file.name) {
                setFileLoading(true);
                await uploadProfilePicture(file, doctor.uuid);
                setProfilePic(profilePic += "8");
                setFileLoading(false);
            }
        }

        upload()
    }, [file])

    return (
        <ScrollView style={styles.page} showsVerticalScrollIndicator={false}>
            <Text style={[styles.title]}>Réglages</Text>
            <Text style={[styles.lightText, { marginTop: 5 }]}>Modifiez vos données sur la plateforme</Text>
            <View style={[styles.row, { marginTop: 30, justifyContent: "center" }]}>
                <View style={{ alignItems: "center" }} ref={ref} {...hoverProps} >
                    {
                        doctor.profile_uri ? <Image style={[{ height: 150, width: 150, borderRadius: 150 }]} source={{ uri: profilePic }} /> : <View style={[styles.pp, { height: 150, width: 150, borderRadius: 150 }]} />
                    }

                    {
                        isHovered &&
                        <TouchableOpacity disabled={fileLoading} onPress={async () => {
                            getDocument(setFile);
                        }} style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", height: 150, width: 150, borderRadius: 150, position: "absolute", zIndex: 1, justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ fontWeight: "600", color: "white" }} >Changer de photo</Text>
                        </TouchableOpacity>
                    }
                    {
                        fileLoading && <ActivityIndicator color={"rgb(18,72,251)"} style={{ position: "absolute", zIndex: 2, height: 150, width: 150, backgroundColor: "white" }} />
                    }
                    {
                        fileds.map((field, i) => {
                            return (
                                <TextField key={i} data={field} uploadNewData={uploadNewData} />
                            )
                        })
                    }
                    <TouchableOpacity style={styles.signButton} onPress={() => uploadNewData()} disabled={loading}>
                        {loading ?
                            <ActivityIndicator color={"white"} />
                            :
                            <Text style={{ textAlign: "center", color: "white", fontWeight: "600" }}>Modifier</Text>
                        }
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => logout()}>
                        <Text style={styles.authLink}>Se deconnecter</Text>
                    </TouchableOpacity>

                </View>
            </View>
        </ScrollView>
    )
}