import { useNavigation, useRoute } from "@react-navigation/native";
import { Modal } from "../../helpers/Modal"
import { Text, View, TouchableOpacity, ActivityIndicator } from "react-native";
import { styles } from "../../Styles";
import { getDateFrenchFormat } from "../../helpers/functions";
import { useState } from "react";
import { removeTask } from "../../supabase";

export const TaskModal = ({ doctor, setDoctor }) => {

    const route = useRoute();
    const task = route.params.task;      

    const [loading, setLoading] = useState(false);

    const navigation = useNavigation();

    return (
        <Modal title={task.title}>
            <Text style={{ fontWeight: "600" }}></Text>
            <View style={styles.row}>
                <View style={{ marginLeft: 10, justifyContent: "center" }}>
                </View>
            </View>
            <Text style={[styles.lightText, { marginTop: 20 }]}>Tâche de {task.from.slice(0, 5).replace(":", "h")} à {task.to.slice(0, 5).replace(":", "h")} le {getDateFrenchFormat(task.date)}</Text>
            <Text style={[styles.lightText, { color: "gray" }]}>La tâche a été prise le {getDateFrenchFormat(task.created_at.slice(0, 10))}</Text>
            <Text style={[styles.bold, { marginTop: 20 }]}>Contenu de la tâche : {task.content}</Text>
            <TouchableOpacity activeOpacity={0.8} onPress={async () => {
                setLoading(true);
                const data = await removeTask(task.id)
                if (data == null) {
                    const tasks = [...doctor.tasks];
                    tasks.splice(tasks.indexOf(task), 1);
                    
                    setDoctor(old => ({
                        ...old,
                        tasks: tasks
                    }))

                    navigation.goBack()
                }

                setLoading(false);
            }}>
                {loading ? <ActivityIndicator color={"rgb(18,72,251)"} /> : <Text style={[styles.authLink, styles.blueFont, styles.bold]}>Supprimer la tâche</Text>}
            </TouchableOpacity>
        </Modal>
    )
}