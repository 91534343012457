import { Text, View, ScrollView, TextInput, ActivityIndicator, TouchableOpacity } from "react-native"
import { styles } from "../Styles"
import { useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { getClientsFromDoctor } from "../supabase";
import { calculateDuration, getDateFrenchFormat, getName } from "../helpers/functions";

const ClientItem = ({client, visiteNb, doctor}) => {

    const dif = calculateDuration(client.birthdate, new Date());
    const navigation = useNavigation();

    return (
        <TouchableOpacity onPress={() => navigation.navigate("PatientModal", { "client": client })}>
            <View style={{ width: "100%", height: 1, backgroundColor: "lightgray" }} />
            <View style={[styles.row, { marginVertical: 15 }]}>
                <Text style={[styles.bold, styles.blueFont, { width: "20%" }]}>{getName(client.name, client.lastname)}</Text>            
                <Text style={{ width: "20%" }}>{visiteNb}</Text>
                <Text style={{ width: "20%" }}>{client.phone}</Text>
                <Text style={{ width: "20%" }}>{getDateFrenchFormat(client.birthdate)}</Text>
                <Text style={{ width: "20%" }}>{dif.years} ans et {dif.months} mois</Text>
            </View>
        </TouchableOpacity>
    )
}

export const Patients = ({ doctor }) => {

    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getClientsFromDoctor(doctor.uuid)
            setClients(data);
            setLoading(false);
        }

        if (doctor.uuid) {
            fetchData()
        }

    }, [doctor])

    const navigation = useNavigation();

    useEffect(() => {
        if (search.length >= 1) {
            const chunks = search.split(" ");
            const filteredArray = clients.filter((item) => {
                const { lastname, name } = item.client_data;
                const lowerChunks = chunks.map((chunk) => chunk.toLowerCase());

                return lowerChunks.every((chunk) => {
                    const lowerLastname = lastname.toLowerCase();
                    const lowerName = name.toLowerCase();

                    return (
                        lowerLastname.normalize().startsWith(chunk) ||
                        lowerName.normalize().startsWith(chunk)
                    );
                });
            });
            setFilteredClients(filteredArray);
        }
    }, [search])

    return (
        <View style={styles.page} showsVerticalScrollIndicator={false}>
            <Text style={[styles.title]}>Patients</Text>
            <Text style={[styles.lightText, { marginTop: 5 }]}>Consultez et gérez vos fiches patient</Text>
            <View style={[styles.row, styles.spaceBT, { marginTop: 30 }]}>
                <TextInput style={[styles.input, { width: 400 }]} placeholder="Rechercher un patient" placeholderTextColor="gray" value={search} onChangeText={(e) => setSearch(e)} />
            </View>
            <View style={styles.row}>
                {
                    ["Nom Prénom", "Visites", "Téléphone", "Date de naissance", "Age"].map((item, i) => {
                        return (
                            <Text key={i} style={[styles.lightText, { marginTop: 20, width: "20%" }]}>{item}</Text>
                        )
                    })
                }
            </View>
            <ScrollView style={{ marginTop: 20 }}>
                {
                    loading && <ActivityIndicator size={"small"} color={"rgb(18,72,251)"} />
                }
                {
                    search.length == 0 && clients.map((c, i) => {

                        const client = c.client_data;
                        const visiteNb = c.count;

                        return <ClientItem key={i} client={client} visiteNb={visiteNb} doctor={doctor} />
                    })
                }
                {
                    search.length > 0 && filteredClients.map((c, i) => {

                        const client = c.client_data;
                        const visiteNb = c.count;

                        return <ClientItem key={i} client={client} visiteNb={visiteNb} doctor={doctor} />
                    })
                }
                {
                    search.length > 0 && filteredClients.length == 0 && (
                        <View>
                            <Text>Aucun client trouvé...</Text>
                        </View>
                    )
                }
            </ScrollView>
        </View>
    )
}