import { Text, View } from "react-native";
import { styles } from "../Styles";
import { NavBar } from "./NavBar";
import { useState } from "react";
import { Accueil } from "./Accueil";
import { Agenda } from "./Agenda";
import { Patients } from "./Patients";
import { Settings } from "./Settings";

export const Home = ({ session, doctor, setDoctor, profilePic, setProfilePic }) => { 
    
    const [selectedIndex, setSelectedIndex] = useState(0)
    
    return (
        <View style={{ backgroundColor: "white", width: "100vw", height: "100vh" }}>
            <View style={[styles.row, { maxWidth: 1600, alignSelf: "center" }]} >
                <NavBar doctor={doctor} setSelectedIndex={setSelectedIndex} selectedIndex={selectedIndex} profilePic={profilePic} />
                {
                    selectedIndex == 0 && <Accueil doctor={doctor} setSelectedIndex={setSelectedIndex} />
                }
                {
                    selectedIndex == 1 && <Agenda doctor={doctor} />
                }
                {
                    selectedIndex == 2 && <Patients doctor={doctor} />
                }
                {
                    selectedIndex == 3 && <Settings doctor={doctor} setDoctor={setDoctor} profilePic={profilePic} setProfilePic={setProfilePic} />
                }
            </View>
        </View>
    )
}