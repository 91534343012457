import { ActivityIndicator, Image, ScrollView, Text, TouchableOpacity, View, useWindowDimensions } from "react-native"
import { styles } from "../Styles"
import { useEffect, useState } from "react"
import { getClientsFromDoctor } from "../supabase"
import { DAYS, MONTHS, compareTime, days, getName, months } from "../helpers/functions"
import { useNavigation } from "@react-navigation/native"

const Card = ({ text, num, image, color, loading, func }) => {
    return (
        <TouchableOpacity onPress={() => func()} style={[styles.card, styles.spaceBT, { backgroundColor: `rgba(${color}, 0.15)`, borderColor: `rgba(${color}, 0.7)`, borderWidth: 2 }]}>
            <View style={[styles.cardBubble, { backgroundColor: `rgb(${color})`, alignItems: "center", justifyContent: "center" }]}>
                <Image source={image} style={[styles.cardBubble, { tintColor: "rgba(255,255,255,0.9)" }, color == "251,147,18" && { height: 48, width: 48 }]} />
            </View>
            {loading ? <ActivityIndicator color={`rgb(${color})`} /> : <Text style={[styles.title, { marginLeft: 5 }]}>{num}</Text>}
            <View style={[styles.row]}>
                <Text style={[styles.lightText]}>{text}</Text>
                <Image style={{ height: 20, width: 20, marginLeft: 5, tintColor: "gray" }} source={require("../../assets/icons/right-arrow.png")} />
            </View>
        </TouchableOpacity>
    )
}

const Calendar = ({ selectedDate, setSelectedDate }) => {

    const [currentDate, setCurrentDate] = useState(new Date());
    // dates of the months stored in those variables
    const [currentMonth, setCurrentMonth] = useState([]);
    const [nextMonth, setNextMonth] = useState([]);
    // current index to know which month to display
    const [monthIndex, setMonthIndex] = useState(0);

    // function to get all the days in the current month
    const getDaysInMonth = (month, year) => {
        var date = new Date(year, month, 1);
        var days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return days;
    }

    useEffect(() => {

        const d = new Date();
        const month = d.getMonth();
        const year = d.getFullYear();
        const cMonth = getDaysInMonth(month, year);
        var nMonth;
        if (month == 11) {
            nMonth = getDaysInMonth(0, year + 1);
        } else {
            nMonth = getDaysInMonth(month + 1, year);
        }

        const chunkSize = 7;
        for (let i = 0; i < cMonth.length; i += chunkSize) {
            const chunk = cMonth.slice(i, i + chunkSize);
            setCurrentMonth((old) => [...old, chunk]);
        }

        for (let i = 0; i < nMonth.length; i += chunkSize) {
            const chunk = nMonth.slice(i, i + chunkSize);
            setNextMonth((old) => [...old, chunk]);
        }

        d.setDate(d.getDate() + 2)
        const y = d.getFullYear();
        const m = String(d.getMonth() + 1).padStart(2, "0");
        const da = String(d.getDate()).padStart(2, "0");

        setSelectedDate(`${y}-${m}-${da}`);

    }, [])

    return (
        <View style={{
            width: 350, borderRadius: 15
        }}>
            <View style={[styles.row, { justifyContent: "space-between" }]}>
                <View />
                <TouchableOpacity activeOpacity={0.8} onPress={() => {
                    if (monthIndex == 0) {
                        setMonthIndex(1)
                    } else {
                        setMonthIndex(0)
                    }
                }} style={[styles.row, { backgroundColor: "rgb(18,72,251)", padding: 5, borderRadius: 8, paddingHorizontal: 12 }]}>
                    <Text style={{ color: "white", fontWeight: "600" }}>{monthIndex == 0 ? MONTHS[currentDate.getMonth()] : MONTHS[(currentDate.getMonth() + 1) % 12]} </Text>
                    <Text style={{ transform: [{ rotate: "180deg" }], color: "white" }}>^</Text>
                </TouchableOpacity>
            </View>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                {
                    [currentMonth, nextMonth][monthIndex][0] && currentMonth[0].map((day, j) => {

                        return (
                            <View key={j} style={{ width: 40, alignItems: "center" }}>
                                <Text style={{ fontWeight: "bold", marginBottom: 15, marginTop: 20 }}>{monthIndex == 1 ? DAYS[nextMonth[0][j].getDay()] : DAYS[day.getDay()]}</Text>
                            </View>
                        )
                    })
                }
            </View>

            {/* display calendar */}
            <View style={{ marginBottom: 10 }}>
                {
                    [currentMonth, nextMonth][monthIndex].map((week, i) => {
                        return (
                            <View key={i} style={{ flexDirection: "row", justifyContent: "space-between", marginVertical: 6 }}>
                                {
                                    week.length == 7 ? week.map((day, j) => {
                                        const isAvailable = !(monthIndex == 1 ? false : day.getDate() >= currentDate.getDate() ? false : true);
                                        const isSelected = (Number(selectedDate.slice(8, 10)) == day.getDate() && Number(selectedDate.slice(5, 7) - 1) == day.getMonth())
                                        return (
                                            <TouchableOpacity activeOpacity={0.8} onPress={() => {
                                                const year = day.getFullYear();
                                                const month = String(day.getMonth() + 1).padStart(2, "0");
                                                const date = String(day.getDate()).padStart(2, "0");
                                                const formattedDate = `${year}-${month}-${date}`;
                                                setSelectedDate(formattedDate)
                                            }} disabled={!isAvailable} key={j} style={{ width: 40, height: 40, backgroundColor: isAvailable ? isSelected ? "rgb(18,72,251)" : null : null, borderRadius: 10, justifyContent: "center", alignItems: "center", borderWidth: isAvailable ? isSelected ? 0 : 1 : 0, borderColor: "rgb(18,72,251)" }}>
                                                <Text style={{ fontWeight: "bold", color: isAvailable ? isSelected ? "white" : "rgb(18,72,251)" : "gray" }}>{day.getDate()}</Text>
                                            </TouchableOpacity>
                                        )
                                    })
                                        :
                                        [...week, ...Array(7 - week.length).keys()].map((day, j) => {
                                            var isAvailable;
                                            var isSelected;
                                            if (typeof (day) == "object") {
                                                isAvailable = !(monthIndex == 1 ? false : day.getDate() >= currentDate.getDate() ? false : true);
                                                isSelected = (Number(selectedDate.slice(8, 10)) == day.getDate() && Number(selectedDate.slice(5, 7) - 1) == day.getMonth());
                                            }
                                            return (
                                                <View key={j}>
                                                    {
                                                        typeof (day) == "object" ?
                                                            <TouchableOpacity activeOpacity={0.8} onPress={() => {
                                                                const year = day.getFullYear();
                                                                const month = String(day.getMonth() + 1).padStart(2, "0");
                                                                const date = String(day.getDate()).padStart(2, "0");
                                                                const formattedDate = `${year}-${month}-${date}`;
                                                                setSelectedDate(formattedDate)
                                                            }} disabled={!isAvailable} style={{ width: 40, height: 40, backgroundColor: isAvailable ? isSelected ? "rgb(18,72,251)" : null : null, borderRadius: 10, justifyContent: "center", alignItems: "center", borderWidth: isAvailable ? isSelected ? 0 : 1 : 0, borderColor: "rgb(18,72,251)" }}>

                                                                <Text style={{ fontWeight: "bold", color: isAvailable ? isSelected ? "white" : "rgb(18,72,251)" : "gray" }}>{day.getDate()}</Text>

                                                            </TouchableOpacity>
                                                            :
                                                            <View style={{ height: 40, width: 40 }} />
                                                    }
                                                </View>
                                            )
                                        })
                                }
                            </View>
                        )
                    })
                }
            </View>
        </View>
    )
}

const RdvComponent = ({ rdv, from = null, width }) => {

    const navigation = useNavigation()

    return (
        <TouchableOpacity activeOpacity={0.8} onPress={() => navigation.navigate("RdvModal", { rdv: rdv })} style={[{ marginTop: 14 }]}>
            <View style={{ width: from ? "calc(100vw - 220px - 120px - 60px - 400px)" : "100%", maxWidth: 810, left: from ? 50 : 0, height: 1, backgroundColor: "lightgray", marginBottom: 14 }} />
            {from ?
                <View style={[styles.row ]}>
                    <Text style={[styles.bold, styles.blueFont, { paddingRight: 20 }]} >{from}</Text>
                    <View style={[styles.row, { width: "calc(100% - 59px)" }]}>
                        <Text style={{ width: "33.3%", maxWidth: 254, fontWeight: "600" }} numberOfLines={1}>{getName(rdv.client.name, rdv.client.lastname)}</Text>
                        <Text style={[{ width: "33.3%", maxWidth: 254, }]} numberOfLines={1}>{rdv.from.slice(0, 5).replace(":", "h")} à {rdv.to.slice(0, 5).replace(":", "h")}</Text>
                        <Text style={{ width: "33.3%", maxWidth: 254, }} numberOfLines={1}>{rdv.reason == null ? "..." : rdv.reason}</Text>
                    </View>
                </View>
                :
                <View style={styles.row}>
                    <Text style={{ width: "33%", fontWeight: "600" }} numberOfLines={1}>{getName(rdv.client.name, rdv.client.lastname)}</Text>
                    <Text style={[{ width: "33%" }]} numberOfLines={1}>{rdv.from.slice(0, 5).replace(":", "h")} à {rdv.to.slice(0, 5).replace(":", "h")}</Text>
                    <Text style={{ width: "33%" }} numberOfLines={1}>{rdv.reason == null ? "..." : rdv.reason}</Text>
                </View>
            }
        </TouchableOpacity>
    )
}

const RdvListOfDayX = ({ X, text, doctor, setSelectedIndex }) => {

    const [loading, setLoading] = useState(true);
    const [dateRdv, setDateRdv] = useState([])

    useEffect(() => {
        if (doctor.uuid) {
            const currentDate = X;
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const day = String(currentDate.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`;
            var rdvs = doctor.rdv.filter((obj) => obj.date == formattedDate);
            rdvs.sort((a, b) => a.from > b.from)
            setDateRdv(rdvs);
            setLoading(false);
        }
    }, [doctor])

    const width = useWindowDimensions().width;

    return (
        <View style={{ width: "calc(50% - 30px)" }}>
            <TouchableOpacity style={[styles.row]} onPress={() => setSelectedIndex(1)}>
                <Text style={[styles.header]} >Vos rendez-vous d{text}</Text>
                <Image style={{ height: 24, width: 24, marginLeft: 5 }} source={require("../../assets/icons/right-arrow.png")} />
            </TouchableOpacity>
            <View style={[styles.row, styles.spaceBT, { marginTop: 10 }]} >
                {
                    dateRdv.length > 0 && ["Patient", "Horraire", "Motif"].map((el, i) => {
                        if (width < 1300 && i == 2) {
                            return null;
                        }
                        return (
                            <Text key={i} style={[styles.lightText, { width: width < 1300 ? "49.5%" : "33%" }]}>{el}</Text>
                        )
                    })
                }
            </View>
            {loading ?
                <View style={{ height: 250, width: "100%", justifyContent: "center", alignItems: "center" }}>
                    <ActivityIndicator color="gray" />
                </View>
                :
                dateRdv.length == 0 ?
                    <View style={{ height: 250, width: "100%", justifyContent: "center", alignItems: "center" }}>
                        <Text style={[styles.lightText]}>Aucun rendez-vous ce jour là</Text>
                    </View>
                    :
                    <ScrollView style={{ maxHeight: 250 }} showsVerticalScrollIndicator={false}>
                        {
                            dateRdv.sort((a, b) => compareTime(a.from, b.from)).map((rdv, i) => {
                                return <RdvComponent key={i} rdv={rdv} />
                            })
                        }
                    </ScrollView>
            }
        </View>
    )
}

export const Accueil = ({ doctor, setSelectedIndex }) => {

    const [clients, setClients] = useState(0);
    const [rdvs, setRdvs] = useState(0);
    const [nextRdv, setNextRdv] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState();

    const today = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const width = useWindowDimensions().width;
    const navigation = useNavigation();

    useEffect(() => {
        const fetchClients = async () => {
            if (doctor.uuid) {
                const clients = await getClientsFromDoctor(doctor.uuid)
                setClients(clients.length);
                setLoading(false);
            }
        }

        const getNextRdv = () => {
            const currentDate = new Date();

            const filteredArray = doctor.rdv.filter(obj => {
                const objDate = obj.date.split('-');
                const objTime = obj.to.split(':');
                const objDateTime = new Date(objDate[0], objDate[1] - 1, objDate[2], objTime[0], objTime[1], objTime[2]);
                return objDateTime > currentDate;
            });

            const sortedArray = filteredArray.sort((a, b) => {
                const dateA = a.date.split('-');
                const timeA = a.to.split(':');
                const dateB = b.date.split('-');
                const timeB = b.to.split(':');
                return new Date(dateA[0], dateA[1] - 1, dateA[2], timeA[0], timeA[1], timeA[2]) - new Date(dateB[0], dateB[1] - 1, dateB[2], timeB[0], timeB[1], timeB[2]);
            });

            const nextObject = sortedArray[0];

            setRdvs(sortedArray.length);

            if (nextObject) {                
                setNextRdv(nextObject);
            } else {
                setNextRdv({})
            }
        }

        if (doctor.rdv) {
            getNextRdv()
            fetchClients()
            const interval = setInterval(() => {
                getNextRdv()
            }, 1000 * 60);

            return () => clearInterval(interval);
        }

    }, [doctor])

    const cards = [
        {
            text: "Patients actifs",
            num: clients,
            image: require("../../assets/icons/user.png"),
            color: "18,72,251",
            function: () => setSelectedIndex(2)
        },
        {
            text: "Rendez-vous à venir",
            num: rdvs,
            image: require("../../assets/icons/calendar.png"),
            color: "251,147,18",
            function: () => setSelectedIndex(1)
        },
        {
            text: "Horraire du prochain rendez-vous",
            num: nextRdv.from ? nextRdv.from.slice(0, 5).replace(":", "h") : null,
            image: require("../../assets/icons/clock.png"),
            color: "139,18,251",
            function: () => {Object.keys(nextRdv).length > 0 ? navigation.navigate("RdvModal", { rdv: nextRdv }) : alert("Vous n'avez pas de rendez-vous à venir")}
        }
    ]

    return (
        <ScrollView style={styles.page} showsVerticalScrollIndicator={false}>
            <Text style={[styles.title]}>Bonjour {doctor.name} !</Text>
            <Text style={[styles.lightText, { marginTop: 5 }]}>Aujourd'hui, nous sommes {days[today.getDay()]} {today.getDate()} {months[today.getMonth()]} {today.getFullYear()}</Text>
            <View style={[styles.row, styles.spaceBT, { marginTop: 30 }]}>
                {
                    cards.map((c, i) => {
                        return <Card key={i} text={c.text} num={c.num} loading={loading} image={c.image} color={c.color} func={c.function} />
                    })
                }
            </View>
            <View style={[styles.row, styles.spaceBT, { marginTop: 60 }]}>
                {
                    [{ txt: "'aujourd'hui", date: today }, { txt: "e demain", date: tomorrow }].map((obj, i) => {
                        return <RdvListOfDayX text={obj.txt} key={i} X={obj.date} doctor={doctor} setSelectedIndex={setSelectedIndex} />
                    })
                }
            </View>
            <View style={[styles.row, styles.spaceBT, { marginTop: 60 }]}>
                <Calendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                <View style={[{ width: "calc(100vw - 220px - 120px - 60px - 350px)", maxWidth: 850 }]}>
                    {
                        selectedDate &&
                        <View>
                            {!loading && <Text style={styles.header}>Vos rendez-vous pour le {days[new Date(selectedDate).getDay()]} {selectedDate.slice(8, 10)} {months[selectedDate.slice(5, 7)]}</Text>}
                            <View style={[styles.row, { marginTop: 10, paddingLeft: 70 }]} >
                                {
                                    !loading && doctor.rdv.filter((obj) => obj.date == selectedDate).length > 0 && ["Patient", "Horraire", "Motif"].map((el, i) => {                                        
                                        return (
                                            <Text key={i} style={[styles.lightText, { width: "calc((100%) / 3)", maxWidth: 254 }]}>{el}</Text>
                                        )
                                    })
                                }
                            </View>
                            {!loading && doctor.rdv.filter((obj) => obj.date == selectedDate).length > 0 && <ScrollView style={{ paddingLeft: 10, marginTop: 10, height: 350 }}>
                                {
                                    doctor.rdv.filter((obj) => obj.date == selectedDate).sort((a, b) => compareTime(a.from, b.from)).map((el, i) => {
                                        return <RdvComponent key={i} rdv={el} from={el.from.slice(0, 5)} width={width} />
                                    })
                                }
                            </ScrollView>}

                            {selectedDate && !loading && doctor.rdv.filter((obj) => obj.date == selectedDate).length == 0 &&
                                <View style={{ marginTop: 10, height: 350, justifyContent: "center", alignItems: "center" }}>
                                    <Text style={{ color: "gray", textAlign: "center" }}>Vous n'avez pas de rendez-vous ce jour là</Text>
                                </View>
                            }
                        </View>
                    }
                </View>
            </View>
        </ScrollView>
    )
}